import { FC, useState, useEffect } from "react";
import { Table } from "antd";

import styles from "./table.module.scss";

import { ColumnsType } from "antd/lib/table";
import finished from "../../../../src/assets/images/finished.png";
import inCourse from "../../../../src/assets/images/inCourse.png";
import programmed from "../../../../src/assets/images/programmed.png";
import { DetailsHomeworkDrawer } from "../DetailsHomeworkDrawer";
import { DrawerStudentResponse } from "../DetailsHomeworkDrawer/TaskDescription/DrawerStudentResponse";
import { DrawerInteligentTeacher } from "../DetailsHomeworkDrawer/StudentsStatusTable/DrawerInteligentTeacher";
import { DrawerConversation } from "../DetailsHomeworkDrawer/DrawerConversation";

interface TableProps {
  dataSource: any;
  isLoading: any;
}

const CalificationsTable: FC<TableProps> = ({ dataSource, isLoading }) => {
  const [taskId, setTaskId] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const [studentNameColumnWidth, setStudentNameColumnWidth] = useState(0);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleDrawerConversation, setVisibleDrawerConversation] =
    useState(false);
  const [userIdSetted, setUserIdSetted] = useState<number | null>(null);
  const [visibleModalStudentResponse, setVisibleModalStudentResponse] =
    useState(false);
  const onClose = () => {
    setVisible(false);
  };
  let orderHomeworks = (homeworks: any) => {
    homeworks.forEach((hom: any) => {
      if (typeof hom.end_date === "string") {
        let day = hom?.end_date?.slice(0, 2);
        let month = hom?.end_date?.slice(3, 5);
        let year = hom?.end_date?.slice(6, 10);
        let hour = hom?.end_date?.slice(10, 16);

        let newEndDate = new Date(`${month}-${day}-${year} ${hour}`);

        hom["new_end_date"] = newEndDate;
      }
    });
    return homeworks.sort(
      (a: { new_end_date: number }, b: { new_end_date: number }) =>
        a.new_end_date < b.new_end_date
          ? 1
          : a.new_end_date > b.new_end_date
          ? -1
          : 0
    );
  };
  let renderStudentResult = (_: any, record: any, homework: any) => {
    let studentActualHomework = record?.homeworks
      ?.filter((studentHomework: any) => {
        return studentHomework.id === homework.id;
      })
      .pop();

    let result;
    if (studentActualHomework === undefined || studentActualHomework === null) {
      result = "•";
    } else if (
      studentActualHomework?.result === null &&
      studentActualHomework?.status !== "programed"
    ) {
      result = "×";
    } else if (
      studentActualHomework?.result === null &&
      studentActualHomework?.status === "programed"
    ) {
      result = "--";
    } else if (
      studentActualHomework?.result !== null ||
      studentActualHomework?.average !== null
    ) {
      result =
        studentActualHomework?.result || studentActualHomework?.result === 0
          ? studentActualHomework.result
          : studentActualHomework.average;
    }
    return (
      <div className={styles.descriptionContainer}>
        <h3
          onClick={() => {
            if (
              studentActualHomework?.result ||
              studentActualHomework?.result === 0
            ) {
              setUserIdSetted(record.student_id);
              setTaskId(homework.id);
              if (
                homework?.lesson_type === "evaluation" ||
                homework?.lesson_type === "videolesson"
              ) {
                setVisibleModalStudentResponse(true);
              }
              if (homework?.lesson_type === "redaction") {
                setVisibleDrawer(true);
              }
              if (homework?.lesson_type === "conversation") {
                setVisibleDrawerConversation(true);
              }
            }
          }}
          className={
            record?.alert?.length === 0
              ? styles.descriptionTotalAverage
              : typeof result !== "string"
              ? Number(result) >= 6
                ? styles.tableHighScoreTask
                : styles.tableLowScoreTask
              : styles.descriptionFontResult
          }
        >
          {result}
        </h3>
      </div>
    );
  };

  let homeworkTitle = (homework: any) => {
    let maxLength = 23;
    let homeworkTitle =
      homework?.title?.length < maxLength
        ? homework?.title
        : homework?.title?.substring(0, maxLength) + "...";
    return (
      <div
        className={
          dataSource[0].homeworks?.length === 1
            ? styles.titleDescriptionContainer
            : styles.titleDescriptionContainer
        }
      >
        <h3
          className={styles.titleDescriptionFont}
          onClick={() => {
            setVisible(true);
            setTaskId(homework.id);
          }}
        >
          {homeworkTitle}
        </h3>
        <div className={styles.statusDate}>
          <img
            src={
              homework.status === "finished"
                ? finished
                : homework.status === "inCourse"
                ? inCourse
                : programmed
            }
            alt="calendar"
          />

          <h3 className={styles.descriptionFont}>
            {homework?.end_date?.slice(0, 5)}
          </h3>
        </div>
      </div>
    );
  };

  const completedHomeworksColumnTitle = (title1: string, title2: string) => {
    return (
      <div className={styles.completedTitleContainer}>
        <h3 className={styles.completedTitleFont}>{title1}</h3>
        <h3 className={styles.completedTitleFont}>{title2}</h3>
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "Alumno",
      dataIndex: "total_average",
      key: "student_last__name",
      fixed: "left",
      width: 150,
      sorter: {
        compare: (
          a: { alert: any; student_last__name: any },
          b: { alert: any; student_last__name: any }
        ) => {
          if (a.alert === "" || b.alert === "") return 0;
          return (
            a?.student_last__name ? a.student_last__name : ""
          ).localeCompare(b?.student_last__name ? b.student_last__name : "");
        },
        multiple: 1,
      },
      sortDirections: ["descend", "ascend", "descend"],
      defaultSortOrder: "ascend",
      render: (_: any, { student_name }: any) => {
        return (
          <div
            className={
              _ !== undefined
                ? styles.tableRows
                : styles.descriptionStudentNameContainer
            }
          >
            <h3 className={styles.descriptionFontName}>{student_name}</h3>
          </div>
        );
      },
    },
    {
      title: completedHomeworksColumnTitle("Completadas/", "Finalizadas"),
      dataIndex: "total_homeworks",
      key: "completed_homeworks",
      fixed: "left",
      width: studentNameColumnWidth,
      align: "center",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: {
        compare: (a, b) => a?.completed_homeworks - b?.completed_homeworks,
        multiple: 2,
      },
      render: (
        _: string | any[] | undefined,
        { completed_homeworks, total_homeworks }: any
      ) => {
        let result;
        if (_ !== undefined && _.length === 0) {
          result = _;
        } else if (
          completed_homeworks !== undefined &&
          total_homeworks !== undefined
        ) {
          result = `${completed_homeworks}/${total_homeworks}`;
        } else {
          result = _;
        }

        return (
          <div
            className={
              _ !== undefined && _.length === 0
                ? styles.tableRows
                : styles.descriptionContainer
            }
          >
            <h3 className={styles.descriptionFont}>{result}</h3>
          </div>
        );
      },
    },

    {
      title: "Promedio Total",
      dataIndex: ["total_average"],
      key: "total_average",
      width: studentNameColumnWidth,
      fixed: "left",
      align: "center",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: { compare: (a, b) => a?.total_avg - b?.total_avg, multiple: 3 },

      render: (_: undefined, { total_avg }: any) => {
        let result;
        if (_ !== undefined) {
          result = _;
        } else if (total_avg === null) {
          result = 0;
        } else {
          result = `${total_avg}`;
        }

        return (
          <div className={styles.descriptionContainer}>
            <h3
              className={
                Number(result) >= 6
                  ? styles.tableHighScore
                  : styles.tableLowScore
              }
            >
              {result}
            </h3>
          </div>
        );
      },
    },
  ];

  //homeworks columns creation
  let homeworksArray = [];
  const homeworksAlreadyChecked: any = [];

  dataSource?.forEach((student: any) => {
    orderHomeworks(student.homeworks);
    homeworksArray = student.homeworks.slice(0, 20);

    homeworksArray.forEach((homework: any) => {
      //to create no more than one column
      if (!homeworksAlreadyChecked.includes(homework.id)) {
        columns.push({
          title: homeworkTitle(homework),
          dataIndex: student,
          key: homework.id,
          width: 100,
          render: (_, record) => {
            return renderStudentResult(_, record, homework);
          },
        });
      } else {
        //group homeworks or individual assigned to student/s
        columns.forEach((column: any) => {
          if (column.key === homework.id) {
            column["render"] = (_: any, record: any) => {
              return renderStudentResult(_, record, homework);
            };
          }
        });
      }
      homeworksAlreadyChecked.push(homework.id);
    });
  });

  useEffect(() => {
    const columnsLength = columns?.length;
    columnsLength <= 8
      ? setStudentNameColumnWidth(85)
      : columnsLength >= 20
      ? setStudentNameColumnWidth(105)
      : setStudentNameColumnWidth(110);
  }, [dataSource, columns?.length]);

  return (
    <div>
      <Table
        className={styles.calificationsTable}
        columns={columns}
        dataSource={dataSource}
        scroll={
          columns.length > 3 && columns.length < 10
            ? { x: 0 }
            : columns.length === 3
            ? { x: 0 }
            : { x: 2500 }
        }
        sticky
        rowClassName={(record, dataIndex) => {
          return dataIndex === 0 && typeof record.total_average === "number"
            ? `${styles.tableRows}`
            : "";
        }}
        bordered
        pagination={false}
        size="small"
        loading={isLoading}
      />
      {visible && (
        <DetailsHomeworkDrawer
          back="myclasses"
          taskId={taskId}
          onCloseTask={onClose}
          visibleTask={visible}
        />
      )}
      {visibleDrawer && taskId && (
        <DrawerInteligentTeacher
          userId={userIdSetted}
          taskId={taskId}
          setVisibleDrawer={setVisibleDrawer}
          visibleDrawer={visibleDrawer}
        />
      )}
      {visibleDrawerConversation && taskId && (
        <DrawerConversation
          userId={userIdSetted}
          taskId={taskId}
          setVisibleDrawerConversation={setVisibleDrawerConversation}
          visibleDrawerConversation={visibleDrawerConversation}
        />
      )}
      {visibleModalStudentResponse && userIdSetted && taskId && (
        <DrawerStudentResponse
          setVisibleModalStudentResponse={setVisibleModalStudentResponse}
          visibleModalStudentResponse={visibleModalStudentResponse}
          taskId={Number(taskId)}
          userId={userIdSetted}
        />
      )}
    </div>
  );
};

export default CalificationsTable;
