import React, { FC } from "react";
import styles from "./panelProject.module.scss";
import { Checkbox } from "antd";
import { teacherProjectInput } from "../../../../__generated__/gql-types/globalTypes";
import arrowTop from "../../../../assets/pages/newProject/arrowTop.png";
import arrowDownPanel from "../../../../assets/pages/newProject/arrowDownPanel.png";
import useAuth from "../../../../context/useAuth";

interface PropsPanelProject {
  setProjectInput: (e: teacherProjectInput) => void;
  projectInput: teacherProjectInput | null;
  setOpenForm: (e: boolean) => void;
  openForm: boolean;
}
const PanelProject: FC<PropsPanelProject> = ({
  projectInput,
  setProjectInput,
  setOpenForm,

  openForm,
}) => {
  return (
    <div
      className={styles.container}
      style={!openForm ? { cursor: "pointer" } : {}}
      onClick={() => {
        setOpenForm(true);
      }}
    >
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          CARACTERÍSTICAS GENERALES DEL PROYECTO
        </div>
        {openForm ? (
          <img
            src={arrowTop}
            alt="arrow"
            onClick={(event) => {
              event.stopPropagation();
              event.isPropagationStopped();
              setOpenForm(false);
            }}
          />
        ) : (
          <img src={arrowDownPanel} alt="arrow" />
        )}
      </div>
      {openForm && (
        <div className={styles.content}>
          <div className={styles.containerInputs}>
            <div className={styles.boxInput}>
              <div className={styles.inputLabel}>Grado</div>
              <select
                name="cars"
                id="cars"
                className={styles.selectProject}
                value={projectInput?.grade ?? 1}
                onChange={(e) => {
                  setProjectInput({
                    ...projectInput,
                    grade: Number(e.target.value),
                  });
                }}
              >
                <option value="1">1er grado</option>
                <option value="2">2do grado</option>
                <option value="3">3er grado</option>
                <option value="4">4to grado</option>
                <option value="5">5to grado</option>
                <option value="6">6to grado</option>
              </select>
            </div>
            <div className={styles.boxInput}>
              <div className={styles.inputLabel}>Número alumnos</div>
              <input
                className={styles.inputProject}
                value={projectInput?.students ?? 1}
                onChange={(e) => {
                  if (Number(e.target.value) || Number(e.target.value) === 0) {
                    setProjectInput({
                      ...projectInput,
                      students: e.target.value ? Number(e.target.value) : 0,
                    });
                  }
                }}
                placeholder="e.j. 10"
              ></input>
            </div>
            <div className={styles.boxInput}>
              <div className={styles.inputLabel}>Duración</div>
              <select
                name="cars"
                id="cars"
                className={styles.selectProject}
                value={projectInput?.duration ?? 1}
                onChange={(e) => {
                  setProjectInput({
                    ...projectInput,
                    duration: Number(e.target.value),
                  });
                }}
              >
                <option value="1">1 semana</option>
                <option value="2">2 semanas</option>
                <option value="3">3 semanas</option>
                <option value="4">4 semanas</option>
              </select>
            </div>
            <div className={styles.boxInput}>
              <div className={styles.inputLabel}>Horas semanales</div>
              <select
                name="cars"
                id="cars"
                className={styles.selectProject}
                value={projectInput?.week_hours ?? 1}
                onChange={(e) => {
                  setProjectInput({
                    ...projectInput,
                    week_hours: Number(e.target.value),
                  });
                }}
              >
                <option value="1">1 hora</option>
                <option value="2">2 horas</option>
                <option value="3">3 horas</option>
                <option value="4">4 horas</option>
                <option value="5">5 horas</option>
                <option value="6">6 horas</option>
                <option value="7">7 horas</option>
                <option value="8">8 horas</option>
              </select>
            </div>
          </div>

          <div className={styles.containerSubjects}>
            <div className={styles.containerintertingThopic}>
              <div className={styles.titleSubject}>Tema de interés </div>
              <input
                className={styles.inputSubject}
                placeholder="e.j. Harry Potter, Copa del mundo"
                value={projectInput?.themes_of_interest ?? ""}
                onChange={(e) => {
                  setProjectInput({
                    ...projectInput,
                    themes_of_interest: e.target.value,
                  });
                }}
              ></input>
            </div>
            <div className={styles.containerCheckbox}>
              <div className={styles.titleCheck}> Tipos de actividades</div>

              <Checkbox
                className={styles.styleCheck}
                checked={projectInput?.activity_type === "mixtas"}
                onChange={() =>
                  setProjectInput({ ...projectInput, activity_type: "mixtas" })
                }
              >
                Mixtos
              </Checkbox>
              <Checkbox
                className={styles.styleCheck}
                checked={projectInput?.activity_type === "grupales"}
                onChange={() =>
                  setProjectInput({
                    ...projectInput,
                    activity_type: "grupales",
                  })
                }
              >
                En grupo
              </Checkbox>
              <Checkbox
                className={styles.styleCheck}
                checked={projectInput?.activity_type === "individuales"}
                onChange={() =>
                  setProjectInput({
                    ...projectInput,
                    activity_type: "individuales",
                  })
                }
              >
                Individuales
              </Checkbox>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PanelProject;
