import React, { useEffect, useRef, useState } from "react";
import styles from "./newQuizz.module.scss";
import { QuizHeader } from "./Header";
import { Feedback } from "./Feedback";
import NewRelateConcepts from "../RelateConcepts/NewRelateConcepts";
import NewFill from "../Fill/NewFill";
import NewFraseDrag from "../FraseDragMultiple/NewFraseDrag";
import { NewQuestions } from "../NewQuestions";
import { getLessonById_getLessonById_lesson_content_quizzes_questions_answers } from "../../../../__generated__/gql-types/getLessonById";
import { NewWatchVideo } from "./NewWatchVideo";
import { Drawer } from "antd";
import { Loader } from "../../../Commons";
import { DustbinState } from "../MatchingText/interfaces";
import { DrawerLessonStudent } from "../../ExtraPoints/Lessons";
import { FinishLesson } from "./FinishLesson";
import { PropsColumns } from "../FraseDragMultiple/newUtils";
import {
  PropsArrayRelate,
  ScrollPosition,
  arrayColumnsInitial,
  arrayColumnsInitialMatching,
  handleAudioMediaQuiz,
  handleAudioNegativeQuiz,
  handleAudioPositiveQuiz,
  useFetchLesson,
} from "./utils";
import useIsVerticalMobile from "../../../../hooks/useIsVerticalMobile";
import { ModalError } from "./ModalSave";
import { ModalLinkWrong } from "../../../Shared/ModalLinkWrong";
import { RedactionQuiz } from "../RedactionQuiz";
import UseCreateResponse from "../../../../api/useCreateResponse";
import { LiteracyContent } from "./LiteracyContent";
import { Conversation } from "./Conversartion";
import { ModalConexion } from "../../../Shared/ModalConexion";
import {
  getResponseByUserAndHomework_getResponseByUserAndHomework_block_responses,
  getResponseByUserAndHomework_getResponseByUserAndHomework_relation_responses,
} from "../../../../__generated__/gql-types/getResponseByUserAndHomework";
import { OrderFrase } from "../OrderFrase";
import { compareArrays, InitialData } from "../OrderFrase/utils";
import { QuestionAI } from "../QuestionAI";
import UseGetQuestionResultAI from "../../../../api/useGetQuestionResultAI";
import { ScreenIsVertical } from "./ScreenIsVertical";
import { NewMatching } from "../NewMatching";
import { PropsColumnsMatching } from "../NewMatching/utils";

const NewQuizz = () => {
  const {
    individualLesson,
    loading,
    setLoading,
    setShowVideo,
    showVideo,
    totalQuestion,
    totalVideoQuestion,
    valueQuestion,
    openModalError,
    onCloseModalDelete,
    openModalLinkWrong,
    statusHomework,
    homeworkParams,
    responses,
    setResponses,
    contQuestion,
    contQuestionQuiz,
    setContQuestion,
    setContQuestionQuiz,
    userToken,
    result,
    contResult,
    setContResult,
    setStudentQuestionResult,
    studentQuestionResult,
    callResult,
    sound,
    setSound,
  } = useFetchLesson();
  const [isOnline, setIsOnline] = useState(true);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const checkInternetStatus = () => {
    setIsOnline(navigator.onLine === true);
  };
  const GetQuestionAIResult = UseGetQuestionResultAI();

  const createResponse = UseCreateResponse();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // Guarda la posición del scroll en el estado y en localStorage
  const saveScrollPosition = (x: number, y: number) => {
    const position = { x, y };
    localStorage.setItem("scrollPosition", JSON.stringify(position));
  };

  // Maneja el evento de scroll
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollTop } = scrollContainerRef.current;
      saveScrollPosition(scrollLeft, scrollTop);
    }
  };

  // Restaurar la posición del scroll al montar el componente
  useEffect(() => {
    const savedPosition = localStorage.getItem("scrollPosition");
    if (savedPosition && scrollContainerRef.current) {
      const { x, y }: ScrollPosition = JSON.parse(savedPosition);
      scrollContainerRef.current.scrollLeft = x;
      scrollContainerRef.current.scrollTop = y;
    }
  }, []);

  // Añadir el evento de scroll
  useEffect(() => {
    const currentRef = scrollContainerRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollContainerRef?.current?.scrollHeight, handleScroll]);

  const [seeInstructions, setSeeInstructions] = useState(false);
  const [alertFalse, setAlertFalse] = useState(false);
  const [alertTrue, setAlertTrue] = useState(false);
  const [alertMid, setAlertMid] = useState(false);
  const [stateNext, setStateNext] = useState(false);
  const [visible, setVisible] = useState(false);
  const [play, setPlay] = useState(false);

  // contQuizzVideo = cantidad de quiz intra video
  const [contQuizzVideo, setContQuizzVideo] = useState<number>(0);
  const [contQuestionsVideo, setContQuestionsVideo] = useState<number>(0);
  const [selectedKeyLiteracy, setSelectedKeyLiteracy] = useState("1");
  const [arrayRelate, setArrayRelate] = useState<PropsArrayRelate[]>([]);
  const [temporalInputFill, setTemporalInputFill] = useState("");
  const [activeIndex, setActiveIndex] = useState<number[]>([]);
  const [videoContinuar, setVideoContinuar] = useState(false);
  const [itemsMatched, setItemsMatched] = useState(false);
  const [dustbins, setDustbins] = useState<DustbinState[]>();
  const [droppedBoxNames, setDroppedBoxNames] = useState<string[]>([]);
  const [answers, setAnswer] = useState<
    getLessonById_getLessonById_lesson_content_quizzes_questions_answers[]
  >([]);
  const [columns, setColumns] = useState<PropsColumns[]>(arrayColumnsInitial);
  const [responsesStudent, setResponseStudent] = useState<any[]>([]);
  const isVerticalMobile = useIsVerticalMobile(setPlay);
  const [qualitySelected, setQualitySelected] = useState(1080);
  const [seconds, setSeconds] = useState(0);
  const [stateColumns, setStateColumns] = useState<InitialData | null>(null);
  const [columnsMatching, setColumnsMatching] = useState<
    PropsColumnsMatching[]
  >([]);

  //--------------------------------
  // studentsAnswersAverageStatus vars
  const goodAnswer = {
    student_id: userToken?.id,
    question_id:
      individualLesson &&
      individualLesson?.lesson_content?.quizzes &&
      individualLesson?.lesson_content?.quizzes?.length > 0 &&
      individualLesson?.lesson_content?.quizzes[0]?.questions &&
      Number(
        individualLesson?.lesson_content?.quizzes[0]?.questions[
          contQuestionQuiz
        ]?.id
          ? individualLesson?.lesson_content?.quizzes[0]?.questions[
              contQuestionQuiz
            ]?.id
          : 0
      ),
    result_status: "good",
  };
  const uglyAnswer = {
    student_id: userToken?.id,
    question_id:
      individualLesson &&
      individualLesson?.lesson_content?.quizzes &&
      individualLesson?.lesson_content?.quizzes?.length > 0 &&
      individualLesson?.lesson_content?.quizzes[0]?.questions &&
      Number(
        individualLesson?.lesson_content?.quizzes[0]?.questions[
          contQuestionQuiz
        ]?.id
      ),
    result_status: "ugly",
  };
  const badAnswer = {
    student_id: userToken?.id,
    question_id:
      individualLesson &&
      individualLesson?.lesson_content?.quizzes &&
      individualLesson?.lesson_content?.quizzes?.length > 0 &&
      individualLesson?.lesson_content?.quizzes[0]?.questions &&
      Number(
        individualLesson?.lesson_content?.quizzes[0]?.questions[
          contQuestionQuiz
        ]?.id
      ),
    result_status: "bad",
  };
  //--------------------------------

  const onClose = () => {
    setVisible(false);
  };
  const putFocusFooter = () => {
    buttonRef.current && buttonRef.current.focus();
  };
  const handleInstructions = () => {
    setSeeInstructions(!seeInstructions);
  };

  ///Funciones de COMPROBAR footer////
  const functionContinuarRelate = async () => {
    let questionId =
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .id;
    const contArrayRelate = arrayRelate.filter((element) => {
      return element !== null && element !== undefined;
    }).length;

    setResponseStudent([
      ...responsesStudent,
      {
        game: arrayRelate,
        type: individualLesson!.lesson_content?.quizzes![0].questions![
          contQuestionQuiz
        ].type_id,
      },
    ]);

    if (
      contArrayRelate >=
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .relationQuestion![0].block1!.length
    ) {
      let cont = 0;
      arrayRelate.forEach((element) => {
        if (
          element.first === element.second &&
          (element.alpha || element.alpha === 0)
        ) {
          cont = cont + 1;
        }
      });

      const relationQuestion =
        individualLesson!.lesson_content?.quizzes![0].questions![
          contQuestionQuiz
        ].relationQuestion![0];

      const relationResponse:
        | getResponseByUserAndHomework_getResponseByUserAndHomework_relation_responses[][]
        | null = [];

      arrayRelate
        .sort((a, b) => a.alpha! - b.alpha!)
        .forEach((customRepsonse) => {
          const firstElement = relationQuestion.block1?.find(
            (elem) => elem.coincidence === customRepsonse.first
          );
          const secondElement = relationQuestion.block2?.find(
            (elem) => elem.coincidence === customRepsonse.second
          );

          if (firstElement && secondElement) {
            relationResponse.push([
              firstElement as unknown as getResponseByUserAndHomework_getResponseByUserAndHomework_relation_responses,
              secondElement as unknown as getResponseByUserAndHomework_getResponseByUserAndHomework_relation_responses,
            ]);
          }
        });

      const note = arrayRelate.length / cont;
      let finalNote = 0;
      if (note === 1) {
        setAlertTrue(true);
        finalNote = valueQuestion;
        setContResult(contResult + valueQuestion);
        setStudentQuestionResult([...studentQuestionResult, goodAnswer]);
        sound && handleAudioPositiveQuiz();
      } else if (note > 0.5 && note < 1) {
        sound && handleAudioMediaQuiz();
        finalNote = valueQuestion * note;
        setAlertMid(true);
        setStudentQuestionResult([...studentQuestionResult, uglyAnswer]);
        setContResult(contResult + valueQuestion * note);
      } else {
        sound && handleAudioNegativeQuiz();
        setStudentQuestionResult([...studentQuestionResult, badAnswer]);
        setAlertFalse(true);
      }
      setStateNext(true);
      if (homeworkParams) {
        try {
          const indexResponse = responses.findIndex(
            (response) => response.question_id === Number(questionId)
          );

          responses[indexResponse].relation_responses = relationResponse;
          responses[indexResponse].note = finalNote;
          responses[indexResponse].post_homework_response =
            statusHomework?.post_homework!;
          const { id, __typename, ...responseInput } = responses[indexResponse];
          const { relation_responses, ...rest } = responseInput;
          const updatedRelationResponse = relation_responses?.map((rr) => {
            const updateElem = rr.map((elem) => {
              let { __typename, ...restElem } = elem;
              return restElem;
            });
            return updateElem;
          });
          setResponses(responses);
          await createResponse({
            ...responseInput,
            relation_responses: updatedRelationResponse,
          });
        } catch (error) {
          console.log("create response error: ", { error });
        }
      }
    }
  };

  const functionContinuarFrase = async () => {
    let questionId =
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .id;
    const respuestasCorrect =
      individualLesson!.lesson_content?.quizzes![0].questions![
        contQuestionQuiz
      ].answers!.filter((element) => {
        return element.correct === 1;
      });

    setResponseStudent([
      ...responsesStudent,
      {
        game: columns,
        type: individualLesson!.lesson_content?.quizzes![0].questions![
          contQuestionQuiz
        ].type_id,
      },
    ]);
    if (respuestasCorrect.length === 1) {
      let RespCorrect =
        individualLesson!.lesson_content?.quizzes![0].questions![
          contQuestionQuiz
        ].answers!.find((x) => x.id === columns[0]?.taskIds[0]);

      if (respuestasCorrect[0]?.answer === RespCorrect?.answer) {
        setContResult(contResult + valueQuestion);
        setAlertTrue(true);
        sound && handleAudioPositiveQuiz();
        setStudentQuestionResult([...studentQuestionResult, goodAnswer]);
      } else {
        sound && handleAudioNegativeQuiz();
        setStudentQuestionResult([...studentQuestionResult, badAnswer]);
        setAlertFalse(true);
      }
      setStateNext(true);
      if (homeworkParams) {
        try {
          const indexResponse = responses.findIndex(
            (response) => response.question_id === Number(questionId)
          );
          responses[indexResponse].answers_responses = [
            Number(columns[0]?.taskIds[0]),
          ];
          responses[indexResponse].note = valueQuestion;
          responses[indexResponse].post_homework_response =
            statusHomework?.post_homework!;
          const { id, __typename, ...responseInput } = responses[indexResponse];
          setResponses(responses);
          await createResponse(responseInput);
        } catch (error) {
          console.log("create response error: ", { error });
        }
      }
    } else {
      const firstPersonalCorrectResponse = respuestasCorrect.find(
        (x) => x.id === columns[0]?.taskIds[0]
      );
      const secondPersonalCorrectResponse = respuestasCorrect.find(
        (x) => x.id === columns[1]?.taskIds[0]
      );
      const personalCorrectsResponse = [
        firstPersonalCorrectResponse,
        secondPersonalCorrectResponse,
      ];
      const responseIncorrect = personalCorrectsResponse.some(
        (element) => typeof element === "undefined"
      );
      const isOrderEqual = (arr1: any, arr2: any) => {
        if (
          !arr1.some((element: any) => typeof element === "undefined") &&
          arr2
        ) {
          if (arr1.length !== arr2.length) {
            return false;
          }

          for (let i = 0; i < arr1.length; i++) {
            const keys1 = Object.keys(arr1[i]);
            const keys2 = Object.keys(arr2[i]);

            if (keys1.length !== keys2.length) {
              return false;
            }

            for (let key of keys1) {
              if (arr1[i][key] !== arr2[i][key]) {
                return false;
              }
            }
          }
          return true;
        } else {
          return false;
        }
      };

      // Verificar si el orden de los objetos es igual
      const orderResponses = respuestasCorrect.sort(
        (a, b) => a.order! - b.order!
      );
      const areArraysEqual = isOrderEqual(
        personalCorrectsResponse,
        orderResponses
      );
      const validationOneResponse =
        personalCorrectsResponse[0]?.order === 0 ||
        personalCorrectsResponse[1]?.order === 1;
      let finalNote = 0;
      if (areArraysEqual) {
        setContResult(contResult + valueQuestion);
        setAlertTrue(true);
        finalNote = valueQuestion;
        setStudentQuestionResult([...studentQuestionResult, goodAnswer]);
        sound && handleAudioPositiveQuiz();
      } else if (responseIncorrect && validationOneResponse) {
        sound && handleAudioMediaQuiz();
        setStudentQuestionResult([...studentQuestionResult, uglyAnswer]);
        setContResult(contResult + valueQuestion / 2);
        setAlertMid(true);
        finalNote = valueQuestion / 2;
      } else {
        sound && handleAudioNegativeQuiz();
        setStudentQuestionResult([...studentQuestionResult, badAnswer]);
        setAlertFalse(true);
      }
      setStateNext(true);

      try {
        const indexResponse = responses.findIndex(
          (response) => response.question_id === Number(questionId)
        );
        responses[indexResponse].answers_responses = [
          Number(columns[0]?.taskIds[0]),
          Number(columns[1]?.taskIds[0]),
        ];
        responses[indexResponse].note = finalNote;
        responses[indexResponse].post_homework_response =
          statusHomework?.post_homework!;
        const { id, __typename, ...responseInput } = responses[indexResponse];
        setResponses(responses);
        await createResponse(responseInput);
      } catch (error) {
        console.log("create response error: ", { error });
      }
    }
  };

  const functionContinuarFill = async () => {
    let questionId =
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .id;
    let sinCaracteresTemporalInput = temporalInputFill
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .toLowerCase()
      .replace(/\s/g, "");

    let sinCaracteres = individualLesson!.lesson_content
      ?.quizzes![0].questions![contQuestionQuiz].answers![0].answer!.normalize(
        "NFD"
      )
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .toLowerCase()
      .replace(/\s/g, "");
    setResponseStudent([
      ...responsesStudent,
      {
        input_response: temporalInputFill,
        game: sinCaracteresTemporalInput,
        type: individualLesson!.lesson_content?.quizzes![0].questions![
          contQuestionQuiz
        ].type_id,
      },
    ]);
    let finalNote = 0;
    if (sinCaracteresTemporalInput === sinCaracteres) {
      setAlertTrue(true);
      setContResult(contResult + valueQuestion);
      setStudentQuestionResult([...studentQuestionResult, goodAnswer]);
      sound && handleAudioPositiveQuiz();
      finalNote = valueQuestion;
    } else {
      sound && handleAudioNegativeQuiz();
      setStudentQuestionResult([...studentQuestionResult, badAnswer]);
      setAlertFalse(true);
    }
    setStateNext(true);
    if (homeworkParams) {
      try {
        const indexResponse = responses.findIndex(
          (response) => response.question_id === Number(questionId)
        );

        responses[indexResponse].block_responses = [];
        responses[indexResponse].note = finalNote;
        responses[indexResponse].post_homework_response =
          statusHomework?.post_homework!;
        const { id, __typename, ...responseInput } = responses[indexResponse];
        setResponses(responses);
        await createResponse({
          ...responseInput,
          input_response: temporalInputFill,
        });
      } catch (error) {
        console.log("create response error: ", { error });
      }
    }
  };

  const functionContinuarQuestionAI = async () => {
    let questionId =
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .id;
    let imageQuestion =
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .url;

    let trimmedTemporalInput = temporalInputFill.trim();
    const response = await GetQuestionAIResult({
      modelResponse:
        individualLesson!.lesson_content?.quizzes![0].questions![
          contQuestionQuiz
        ].answers![0].answer,
      question:
        individualLesson!.lesson_content?.quizzes![0].questions![
          contQuestionQuiz
        ].question,
      studentResponse: trimmedTemporalInput,
    });
    setResponseStudent([
      ...responsesStudent,
      {
        input_response: trimmedTemporalInput,
        game: trimmedTemporalInput,
        type: individualLesson!.lesson_content?.quizzes![0].questions![
          contQuestionQuiz
        ].type_id,
      },
    ]);

    let finalNote = 0;
    if (response === 1) {
      setAlertTrue(true);
      setContResult(contResult + valueQuestion);
      setStudentQuestionResult([...studentQuestionResult, goodAnswer]);
      sound && handleAudioPositiveQuiz();
      finalNote = valueQuestion;
    } else if (response === 0.5) {
      sound && handleAudioMediaQuiz();
      const nuevaNota = valueQuestion / 2;
      setContResult(contResult + nuevaNota);
      setStudentQuestionResult([...studentQuestionResult, uglyAnswer]);
      setAlertMid(true);
      finalNote = nuevaNota;
    } else {
      sound && handleAudioNegativeQuiz();
      setStudentQuestionResult([...studentQuestionResult, badAnswer]);
      setAlertFalse(true);
    }
    setStateNext(true);
    if (homeworkParams) {
      try {
        const indexResponse = responses.findIndex(
          (response) => response.question_id === Number(questionId)
        );

        responses[indexResponse].block_responses = [];
        responses[indexResponse].note = finalNote;
        responses[indexResponse].post_homework_response =
          statusHomework?.post_homework!;
        const { id, __typename, ...responseInput } = responses[indexResponse];
        setResponses(responses);
        await createResponse({
          ...responseInput,
          input_response: trimmedTemporalInput,
          AI_evaluation: response,
        });
      } catch (error) {
        console.log("create response error: ", { error });
      }
    }
  };

  const functionContinuarQuestions = async () => {
    let totalCorrects: number[] = [];
    let contCorrects: number = 0;
    let contFails: number = 0;
    let questionId =
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .id;

    individualLesson!.lesson_content?.quizzes![0].questions![
      contQuestionQuiz
    ].answers!.forEach((e) => {
      if (e.correct === 1) {
        totalCorrects.push(Number(e.id));
        if (activeIndex.includes(Number(e.id))) {
          contCorrects = contCorrects + 1;
        }
      } else {
        if (activeIndex.includes(Number(e.id))) {
          contFails = contFails + 1;
        }
      }
    });
    setResponseStudent([
      ...responsesStudent,
      {
        game: activeIndex,
        type: individualLesson?.lesson_content?.quizzes![0].questions![
          contQuestionQuiz
        ].type_id,
      },
    ]);
    if (activeIndex.length >= 1) {
      const note = contCorrects / totalCorrects.length;
      const noteFails = contFails / totalCorrects.length;
      let finalNote = 0;
      if (note === 1 && contFails === 0) {
        sound && handleAudioPositiveQuiz();
        setAlertTrue(true);
        setStudentQuestionResult([...studentQuestionResult, goodAnswer]);
        setContResult(contResult + valueQuestion);

        finalNote = valueQuestion;
      } else if (note >= 0.5 && noteFails <= 0.5) {
        sound && handleAudioMediaQuiz();
        const nuevaNota = valueQuestion * note;
        setContResult(contResult + nuevaNota);
        setStudentQuestionResult([...studentQuestionResult, uglyAnswer]);
        setAlertMid(true);
        finalNote = nuevaNota;
      } else {
        sound && handleAudioNegativeQuiz();
        setStudentQuestionResult([...studentQuestionResult, badAnswer]);
        setAlertFalse(true);
      }
      setStateNext(true);
      if (homeworkParams) {
        try {
          const indexResponse = responses.findIndex(
            (response) => response.question_id === Number(questionId)
          );
          responses[indexResponse].answers_responses = activeIndex;
          responses[indexResponse].post_homework_response =
            statusHomework?.post_homework!;
          responses[indexResponse].note = finalNote;
          const { id, __typename, ...responseInput } = responses[indexResponse];
          setResponses(responses);
          await createResponse(responseInput);
        } catch (error) {
          console.log("create response error: ", { error });
        }
      }
    }
  };

  const functionContinuarMatchingText = async () => {
    let questionId =
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .id;
    console.log(
      individualLesson?.lesson_content?.quizzes?.[0].questions?.[
        contQuestionQuiz
      ].dAndDQuestion
    );
    const blockItems =
      individualLesson?.lesson_content?.quizzes?.[0].questions?.[
        contQuestionQuiz
      ].dAndDQuestion?.[0].blockItems;
    const blockContainer =
      individualLesson?.lesson_content?.quizzes?.[0].questions?.[
        contQuestionQuiz
      ].dAndDQuestion?.[0].blockContainer;

    const isNotAllow = columnsMatching.some(
      (column) => column.id > 4 && !column.taskIds
    );

    console.log(columnsMatching);
    const matchedItems = columnsMatching
      ?.map((item) => {
        const matchedColumn = blockItems?.find(
          (col) => col?.text === item?.taskIds
        );
        if (item?.taskIds && matchedColumn) {
          return {
            ...item,
            column: matchedColumn.text ?? "",
            coincidence: matchedColumn.coincidence ?? 0,
          };
        }
      })
      .filter((e) => e !== undefined);

    const result = matchedItems.every(
      (item, index) => item?.coincidence === blockContainer?.[index].coincidence
    );
    if (!isNotAllow) {
      setResponseStudent([
        ...responsesStudent,
        {
          game: dustbins,
          type: individualLesson!.lesson_content?.quizzes![0].questions![
            contQuestionQuiz
          ].type_id,
        },
      ]);

      const matchingResponse:
        | getResponseByUserAndHomework_getResponseByUserAndHomework_block_responses[][]
        | null = [];

      matchedItems.forEach((e, index) => {
        const secondElement = blockContainer?.find(
          (e, indexBlock) => indexBlock === index
        );

        const firstElement = blockItems?.find(
          (item) => item?.coincidence && e?.coincidence === item?.coincidence
        );
        firstElement &&
          matchingResponse.push([
            secondElement as unknown as getResponseByUserAndHomework_getResponseByUserAndHomework_block_responses,
            firstElement as unknown as getResponseByUserAndHomework_getResponseByUserAndHomework_block_responses,
          ]);
      });

      if (result) {
        setItemsMatched(true);
        setAlertTrue(true);
        setStudentQuestionResult([...studentQuestionResult, goodAnswer]);
        sound && handleAudioPositiveQuiz();
        setContResult(contResult + valueQuestion);
      } else {
        setStudentQuestionResult([...studentQuestionResult, badAnswer]);
        sound && handleAudioNegativeQuiz();
        setAlertFalse(true);
        setItemsMatched(false);
      }

      if (homeworkParams) {
        try {
          const indexResponse = responses.findIndex(
            (response) => response.question_id === Number(questionId)
          );

          responses[indexResponse].block_responses = matchingResponse;
          const { id, __typename, ...responseInput } = responses[indexResponse];
          const { block_responses, ...rest } = responseInput;
          responses[indexResponse].post_homework_response =
            statusHomework?.post_homework!;
          const updatedDADResponse = block_responses?.map((rr) => {
            const updateElem = rr.map((elem) => {
              let { __typename, ...restElem } = elem;
              return restElem;
            });
            return updateElem;
          });
          setResponses(responses);
          await createResponse({
            ...responseInput,
            block_responses: updatedDADResponse,
          });
        } catch (error) {
          console.log("create response error: ", { error });
        }
      }

      setStateNext(true);
    }
  };

  const functionContinuarOrderFrase = async () => {
    const contAnswer =
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .answers!.length; //Cantidad de respuestas

    const TasksInLastColumn =
      stateColumns?.columns[`column-${contAnswer + 1}`].taskIds.length ?? 0; //Cantidad de fichas en la columna grande
    const TasksInBeforeLastColumn =
      stateColumns?.columns[`column-${contAnswer + 2}`].taskIds.length ?? 0; //Cantidad de fichas en la columna grande

    if (
      contAnswer === TasksInLastColumn + TasksInBeforeLastColumn &&
      stateColumns
    ) {
      //Valida si termino estan todas las fichas en la columna grande

      const tasks = stateColumns?.columns[`column-${contAnswer + 1}`].taskIds
        ?.map((taskId) => stateColumns.tasks[taskId])
        .concat(
          stateColumns?.columns[`column-${contAnswer + 2}`].taskIds?.map(
            (taskId) => stateColumns.tasks[taskId]
          )
        ); // array de objectos de tareas de la columna grande

      // Crear una copia del array original
      let sortedTasks = [...tasks];

      // Ordenar la copia
      sortedTasks.sort((a, b) => a.order - b.order);

      // Comparar los arrays y verifica que sean iguales
      if (compareArrays(tasks, sortedTasks)) {
        setContResult(contResult + valueQuestion);
        setAlertTrue(true);
        sound && handleAudioPositiveQuiz();
        setStudentQuestionResult([...studentQuestionResult, goodAnswer]);
      } else {
        sound && handleAudioNegativeQuiz();
        setStudentQuestionResult([...studentQuestionResult, badAnswer]);
        setAlertFalse(true);
      }
      setResponses(responses);
      setStateNext(true);
      if (homeworkParams) {
        let questionId =
          individualLesson!.lesson_content?.quizzes![0].questions![
            contQuestionQuiz
          ].id;
        const indexResponse = responses.findIndex(
          (response) => response.question_id === Number(questionId)
        );
        const { id, __typename, ...responseInput } = responses[indexResponse];

        await createResponse({
          ...responseInput,
          answers_responses: tasks.map((task) => Number(task.idTask)),
        });
      }
    } else {
      return;
    }
  };

  const functionFirstStep = () => {
    if (videoContinuar && showVideo) {
      if (contQuestion !== totalVideoQuestion) {
        setContQuestion(totalVideoQuestion);
      }
      setShowVideo(false);
      setVideoContinuar(false);
    }
    if (
      individualLesson?.lesson_content?.quizzes![0].questions![
        contQuestionQuiz
      ] !== undefined
    ) {
      if (
        individualLesson.lesson_content?.quizzes[0].questions![contQuestionQuiz]
          .type_id === 1 ||
        individualLesson.lesson_content?.quizzes[0].questions![contQuestionQuiz]
          .type_id === 2
      ) {
        functionContinuarQuestions();
      }

      if (
        individualLesson.lesson_content?.quizzes[0].questions![contQuestionQuiz]
          .type_id === 6 &&
        arrayRelate.every((element) => {
          return element.alpha || element.alpha === 0;
        })
      ) {
        functionContinuarRelate();
      }

      if (
        individualLesson.lesson_content?.quizzes[0].questions![contQuestionQuiz]
          .type_id === 8
      ) {
        functionContinuarOrderFrase();
      }
      if (
        individualLesson.lesson_content?.quizzes[0].questions![contQuestionQuiz]
          .type_id === 4 &&
        temporalInputFill
      ) {
        functionContinuarFill();
      }
      if (
        individualLesson.lesson_content?.quizzes[0].questions![contQuestionQuiz]
          .type_id === 3 &&
        temporalInputFill
      ) {
        functionContinuarQuestionAI();
      }

      if (
        individualLesson.lesson_content?.quizzes[0].questions![contQuestionQuiz]
          .type_id === 7 &&
        columns[0].taskIds.length >= 1
      ) {
        if (columns[2]) {
          if (columns[1].taskIds.length >= 1) {
            functionContinuarFrase();
          }
        } else {
          functionContinuarFrase();
        }
      }
      console.log(
        individualLesson.lesson_content?.quizzes[0].questions![contQuestionQuiz]
          .type_id
      );
      if (
        individualLesson.lesson_content?.quizzes[0].questions![contQuestionQuiz]
          .type_id === 5
      ) {
        functionContinuarMatchingText();
      }
    }
  };

  const functioSecondStepSetState = () => {
    setAlertFalse(false);
    setAlertTrue(false);
    setAlertMid(false);
    setStateNext(false);
    setTemporalInputFill("");
    setActiveIndex([]);
    setDroppedBoxNames([]);
    setStateColumns(null);
    setDustbins([]);
    setDroppedBoxNames([]);
    setAnswer([]);
    setArrayRelate([]);
  };

  ///Funciones de COMPROBAR footer////
  const functionSecondStep = async () => {
    if (individualLesson && contQuestion === totalQuestion - 1) {
      if (navigator.onLine) {
        setLoading(true);
        await callResult();
        setLoading(false);
      } else {
        setIsOnline(false);
        setInterval(checkInternetStatus, 1000);
      }
    } else {
      !showVideo && setContQuestion(contQuestion + 1);
      !showVideo && setContQuestionQuiz(contQuestionQuiz + 1);
    }
  };

  ///Funciones de estilos footer////
  const stylesBotonNextRelate = () => {
    const contArrayRelate = arrayRelate.filter((element) => {
      return element !== null && element !== undefined;
    }).length;

    const totalarray =
      individualLesson?.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .relationQuestion![0]!.block1!.length;
    return totalarray &&
      contArrayRelate >= totalarray &&
      arrayRelate.every((element) => {
        return element?.alpha || element?.alpha === 0;
      })
      ? alertTrue
        ? styles.botonTrue
        : alertFalse
        ? styles.botonFalse
        : styles.botonNormal
      : styles.botonBloqued;
  };

  const stylesBotonNextFill = () => {
    return temporalInputFill
      ? alertTrue
        ? styles.botonTrue
        : alertFalse
        ? styles.botonFalse
        : styles.botonNormal
      : styles.botonBloqued;
  };

  const stylesBotonNextNewQuestions = () => {
    return activeIndex.length >= 1
      ? alertTrue
        ? styles.botonTrue
        : alertFalse
        ? styles.botonFalse
        : styles.botonNormal
      : styles.botonBloqued;
  };
  const stylesBotonNextOrderFrase = () => {
    const contAnswer =
      individualLesson!.lesson_content?.quizzes![0].questions![contQuestionQuiz]
        .answers!.length; //Cantidad de respuestas

    const TasksInLastColumn =
      stateColumns?.columns[`column-${contAnswer + 1}`].taskIds.length ?? 0; //Cantidad de fichas en la columna grande
    const TasksInBeforeLastColumn =
      stateColumns?.columns[`column-${contAnswer + 2}`].taskIds.length ?? 0; //Cantidad de fichas en la columna grande

    return contAnswer === TasksInLastColumn + TasksInBeforeLastColumn
      ? alertTrue
        ? styles.botonTrue
        : alertFalse
        ? styles.botonFalse
        : styles.botonNormal
      : styles.botonBloqued;
  };

  const stylesBotonNextVideo = () => {
    return videoContinuar ? styles.botonNormal : styles.botonBloqued;
  };

  const stylesBotonNextFrase = () => {
    return columns[2]
      ? columns[0].taskIds.length >= 1 && columns[1].taskIds.length >= 1
        ? alertTrue
          ? styles.botonTrue
          : alertFalse
          ? styles.botonFalse
          : styles.botonNormal
        : styles.botonBloqued
      : columns[0].taskIds.length >= 1
      ? alertTrue
        ? styles.botonTrue
        : alertFalse
        ? styles.botonFalse
        : styles.botonNormal
      : styles.botonBloqued;
  };

  const stylesBotonNextMatchingText = () => {
    const isNotAllow = columnsMatching.some(
      (column) => column.id > 4 && !column.taskIds
    );

    return !isNotAllow
      ? alertTrue
        ? styles.botonTrue
        : alertFalse
        ? styles.botonFalse
        : styles.botonNormal
      : styles.botonBloqued;
  };

  const stylesBotonNext = () => {
    if (showVideo) {
      return stylesBotonNextVideo();
    }
    if (
      individualLesson?.lesson_content?.quizzes &&
      individualLesson?.lesson_content?.quizzes[0] &&
      individualLesson?.lesson_content?.quizzes[0].questions &&
      individualLesson?.lesson_content?.quizzes[0].questions[0]
    ) {
      if (
        individualLesson?.lesson_content?.quizzes[0].questions[contQuestionQuiz]
          .type_id === 1 ||
        individualLesson?.lesson_content?.quizzes[0].questions[contQuestionQuiz]
          .type_id === 2
      ) {
        return stylesBotonNextNewQuestions();
      }
      if (
        individualLesson?.lesson_content?.quizzes[0].questions[contQuestionQuiz]
          .type_id === 6
      ) {
        return stylesBotonNextRelate();
      }
      if (
        individualLesson?.lesson_content?.quizzes[0].questions[contQuestionQuiz]
          .type_id === 4
      ) {
        return stylesBotonNextFill();
      }
      if (
        individualLesson?.lesson_content?.quizzes[0].questions[contQuestionQuiz]
          .type_id === 3
      ) {
        return stylesBotonNextFill();
      }
      if (
        individualLesson?.lesson_content?.quizzes[0].questions[contQuestionQuiz]
          .type_id === 7
      ) {
        return stylesBotonNextFrase();
      }
      if (
        individualLesson?.lesson_content?.quizzes[0].questions[contQuestionQuiz]
          .type_id === 8
      ) {
        return stylesBotonNextOrderFrase();
      }
      if (
        individualLesson?.lesson_content?.quizzes[0].questions[contQuestionQuiz]
          .type_id === 5
      ) {
        return stylesBotonNextMatchingText();
      }
    }
  };

  ///Funciones Drawer////
  const stylesBotonNextNewQuestionsVideo = () => {
    let newArray: any = [];
    if (
      individualLesson?.lesson_content?.videos &&
      individualLesson?.lesson_content?.videos[0] &&
      individualLesson?.lesson_content?.videos[0]?.quizzes &&
      individualLesson?.lesson_content?.videos[0]?.quizzes?.length > 0 &&
      contQuizzVideo <
        individualLesson?.lesson_content?.videos[0]?.quizzes?.length
    ) {
      individualLesson.lesson_content?.videos[0]?.quizzes[
        contQuizzVideo
      ]?.quiz?.questions![contQuestionsVideo].answers?.forEach((e: any) => {
        if (e.correct === 1) {
          newArray.push(Number(e.id));
        }
      });

      return activeIndex.length >= 1
        ? alertTrue
          ? styles.botonTrue
          : alertFalse
          ? styles.botonFalse
          : styles.botonNormal
        : styles.botonBloqued;
    } else {
      return styles.botonNormal;
    }
  };

  const functionSecondStepVideoQuiz = () => {
    setAlertFalse(false);
    setAlertTrue(false);
    setAlertMid(false);
    setStateNext(false);
    setActiveIndex([]);
    setAnswer([]);

    if (
      contQuestionsVideo <
      individualLesson!.lesson_content?.videos![0]?.quizzes![contQuizzVideo]
        ?.quiz!.questions!.length -
        1
    ) {
      setContQuestionsVideo(contQuestionsVideo + 1);
    } else {
      setContQuestionsVideo(0);
      setPlay(true);
      onClose();
      setContQuizzVideo(contQuizzVideo + 1);
    }

    setContQuestion(contQuestion + 1);
  };

  const functionContinuarQuestionsVideo = async () => {
    let totalCorrects: number[] = [];
    let contCorrects: number = 0;
    let contFails: number = 0;
    let questionId =
      individualLesson!.lesson_content?.videos![0].quizzes![contQuizzVideo]
        .quiz!.questions![contQuestionsVideo]?.id;

    individualLesson!.lesson_content?.videos![0]?.quizzes![
      contQuizzVideo
    ]?.quiz!.questions![contQuestionsVideo].answers!.forEach((e: any) => {
      if (e.correct === 1) {
        totalCorrects.push(Number(e.id));
        if (activeIndex.includes(Number(e.id))) {
          contCorrects = contCorrects + 1;
        }
      } else {
        if (activeIndex.includes(Number(e.id))) {
          contFails = contFails + 1;
        }
      }
    });

    const goodAnswerIntra = {
      student_id: userToken?.id,
      question_id: individualLesson?.lesson_content?.videos![0].quizzes![
        contQuizzVideo
      ].quiz!.questions![contQuestionsVideo]?.id
        ? Number(
            individualLesson!.lesson_content?.videos![0].quizzes![
              contQuizzVideo
            ].quiz!.questions![contQuestionsVideo]?.id || 0
          )
        : 0,
      result_status: "good",
    };

    const uglyAnswerIntra = {
      student_id: userToken?.id,
      question_id: individualLesson?.lesson_content?.videos![0].quizzes![
        contQuizzVideo
      ].quiz!.questions![contQuestionsVideo]?.id
        ? Number(
            individualLesson!.lesson_content?.videos![0].quizzes![
              contQuizzVideo
            ].quiz!.questions![contQuestionsVideo]?.id || 0
          )
        : 0,
      result_status: "ugly",
    };

    const badAnswerIntra = {
      student_id: userToken?.id,
      question_id: individualLesson?.lesson_content?.videos![0].quizzes![
        contQuizzVideo
      ].quiz!.questions![contQuestionsVideo]?.id
        ? Number(
            individualLesson!.lesson_content?.videos![0].quizzes![
              contQuizzVideo
            ].quiz!.questions![contQuestionsVideo]?.id || 0
          )
        : 0,
      result_status: "bad",
    };

    if (activeIndex.length >= 1) {
      const note = contCorrects / totalCorrects.length;
      const noteFails = contFails / totalCorrects.length;
      let finalNote = 0;
      if (note === 1 && contFails === 0) {
        sound && handleAudioPositiveQuiz();
        setAlertTrue(true);
        setStudentQuestionResult([...studentQuestionResult, goodAnswerIntra]);
        setContResult(contResult + valueQuestion);
        finalNote = valueQuestion;
      } else if (note >= 0.5 && noteFails <= 0.5) {
        sound && handleAudioMediaQuiz();
        const nuevaNota = valueQuestion * note;
        setContResult(contResult + nuevaNota);
        setStudentQuestionResult([...studentQuestionResult, uglyAnswerIntra]);
        setAlertMid(true);
        finalNote = nuevaNota;
      } else {
        sound && handleAudioNegativeQuiz();
        setStudentQuestionResult([...studentQuestionResult, badAnswerIntra]);
        setAlertFalse(true);
      }
      setStateNext(true);
      if (homeworkParams) {
        try {
          const indexResponse = responses?.findIndex(
            (response) => response.question_id === Number(questionId)
          );
          responses[indexResponse].answers_responses = activeIndex;
          responses[indexResponse].note = finalNote;
          const { id, __typename, ...responseInput } = responses[indexResponse];
          setResponses(responses);
          await createResponse(responseInput);
        } catch (error) {
          console.log("create response error: ", { error });
        }
      }
    }
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLButtonElement>
  ) => {
    if (event.key === "Enter") {
      if (stateNext) {
        functioSecondStepSetState();
        await functionSecondStep();
      } else {
        functionFirstStep();
      }
    }
    event.stopPropagation();
    event.isPropagationStopped();
    event.preventDefault();
  };

  return (
    <>
      {loading ? (
        <div className={styles.containerLoading}>
          <Loader></Loader>
        </div>
      ) : (
        <>
          {individualLesson?.lesson_type === "redaction" && (
            <RedactionQuiz
              lesson={individualLesson}
              userToken={userToken}
              homeworkId={homeworkParams}
            />
          )}
          {result && (
            <FinishLesson
              individualLesson={individualLesson}
              userToken={userToken}
              statusHomework={statusHomework}
              puntos={result}
              homeworkParams={homeworkParams}
            />
          )}
          {individualLesson?.lesson_type === "conversation" && (
            <Conversation
              statusHomework={statusHomework}
              individualLesson={individualLesson}
              handleInstructions={handleInstructions}
            ></Conversation>
          )}

          {!result &&
            individualLesson?.lesson_content?.quizzes &&
            individualLesson?.lesson_content?.quizzes?.length > 0 &&
            individualLesson.lesson_content?.quizzes[0]?.questions![
              contQuestionQuiz
            ] !== undefined && (
              <div
                ref={scrollContainerRef}
                className={
                  individualLesson?.lesson_type === "literacy" &&
                  selectedKeyLiteracy === "1" &&
                  !isVerticalMobile
                    ? styles.containerLiteracy
                    : styles.container
                }
                onClick={() => {
                  if (
                    individualLesson?.lesson_content?.quizzes &&
                    individualLesson.lesson_content?.quizzes[0].questions![
                      contQuestionQuiz
                    ].type_id !== 6
                  ) {
                    // buttonRef.current && buttonRef.current.focus(); // revisar aca
                  }
                }}
              >
                <QuizHeader
                  individualLesson={individualLesson}
                  selectedKeyLiteracy={selectedKeyLiteracy}
                  setSelectedKeyLiteracy={setSelectedKeyLiteracy}
                  total={totalQuestion}
                  contProgress={contQuestion}
                  handleInstructions={handleInstructions}
                  qualitySelected={qualitySelected}
                  setQualitySelected={setQualitySelected}
                  isVerticalMobile={isVerticalMobile}
                  sound={sound}
                  setSound={setSound}
                />
                {isVerticalMobile && <ScreenIsVertical />}
                <>
                  {showVideo ? (
                    <div
                      className={styles.content}
                      style={isVerticalMobile ? { display: "none" } : {}}
                    >
                      <NewWatchVideo
                        setVideoContinuar={setVideoContinuar}
                        play={play}
                        individualLesson={individualLesson!}
                        setPlay={setPlay}
                        visible={visible}
                        setVisible={setVisible}
                        seconds={seconds}
                        setSeconds={setSeconds}
                        qualitySelected={qualitySelected}
                      ></NewWatchVideo>
                    </div>
                  ) : (
                    !isVerticalMobile && (
                      <>
                        <div
                          className={styles.showRead}
                          style={
                            !(
                              individualLesson?.lesson_type === "literacy" &&
                              selectedKeyLiteracy === "1"
                            )
                              ? { display: "none" }
                              : {}
                          }
                        >
                          <LiteracyContent
                            literacy={
                              individualLesson?.lesson_content?.literacy
                            }
                            grade={Number(individualLesson.grade?.grade)}
                          />
                        </div>

                        <div
                          className={styles.notShowRead}
                          style={
                            individualLesson?.lesson_type === "literacy" &&
                            selectedKeyLiteracy === "1"
                              ? { display: "none" }
                              : {}
                          }
                        >
                          {individualLesson.lesson_content?.quizzes[0]
                            ?.questions![contQuestionQuiz].type_id === 6 && (
                            <NewRelateConcepts
                              stateNext={stateNext}
                              arrayRelate={arrayRelate}
                              putFocusFooter={putFocusFooter}
                              setArrayRelate={setArrayRelate}
                              content={
                                individualLesson.lesson_content?.quizzes![0]
                                  .questions![contQuestionQuiz]
                              }
                              subject_id={individualLesson?.subject_id}
                            ></NewRelateConcepts>
                          )}
                          {individualLesson.lesson_content?.quizzes[0]
                            ?.questions![contQuestionQuiz].type_id === 4 && (
                            <NewFill
                              alertFalse={alertFalse}
                              alertTrue={alertTrue}
                              setTemporalInputFill={setTemporalInputFill}
                              temporalInputFill={temporalInputFill}
                              stateNext={stateNext}
                              content={
                                individualLesson.lesson_content?.quizzes![0]
                                  .questions![contQuestionQuiz]
                              }
                              subject_id={individualLesson?.subject_id}
                            ></NewFill>
                          )}
                          {individualLesson.lesson_content?.quizzes[0]
                            ?.questions![contQuestionQuiz].type_id === 3 && (
                            <QuestionAI
                              alertFalse={alertFalse}
                              alertTrue={alertTrue}
                              alertMid={alertMid}
                              setTemporalInputFill={setTemporalInputFill}
                              temporalInputFill={temporalInputFill}
                              stateNext={stateNext}
                              content={
                                individualLesson.lesson_content?.quizzes![0]
                                  .questions![contQuestionQuiz]
                              }
                              subject_id={individualLesson?.subject_id}
                            ></QuestionAI>
                          )}
                          {individualLesson.lesson_content?.quizzes[0]
                            ?.questions![contQuestionQuiz].type_id === 8 && (
                            <OrderFrase
                              alertFalse={alertFalse}
                              alertTrue={alertTrue}
                              setStateColumns={setStateColumns}
                              stateColumns={stateColumns}
                              stateNext={stateNext}
                              content={
                                individualLesson.lesson_content?.quizzes![0]
                                  .questions![contQuestionQuiz]
                              }
                              subject={individualLesson?.subject_id}
                            ></OrderFrase>
                          )}
                          {individualLesson.lesson_content?.quizzes[0]
                            .questions![contQuestionQuiz].type_id === 5 && (
                            <NewMatching
                              columnsMatching={columnsMatching}
                              setColumnsMatching={setColumnsMatching}
                              stateNext={stateNext}
                              content={
                                individualLesson.lesson_content?.quizzes![0]
                                  .questions![contQuestionQuiz]
                              }
                              subject={individualLesson?.subject_id}
                            />
                          )}

                          {individualLesson.lesson_content?.quizzes[0]
                            ?.questions![contQuestionQuiz].type_id === 7 && (
                            <NewFraseDrag
                              setColumns={setColumns}
                              alertMid={alertMid}
                              alertTrue={alertTrue}
                              alertFalse={alertFalse}
                              columns={columns}
                              content={
                                individualLesson.lesson_content?.quizzes![0]
                                  .questions![contQuestionQuiz]
                              }
                              subject_id={individualLesson?.subject_id}
                            ></NewFraseDrag>
                          )}
                          {(individualLesson.lesson_content?.quizzes[0]
                            .questions![contQuestionQuiz]?.type_id === 1 ||
                            individualLesson.lesson_content?.quizzes[0]
                              ?.questions![contQuestionQuiz].type_id === 2) && (
                            <NewQuestions
                              answers={answers}
                              setAnswer={setAnswer}
                              stateNext={stateNext}
                              putFocusFooter={putFocusFooter}
                              setActiveIndex={setActiveIndex}
                              activeIndex={activeIndex}
                              content={
                                individualLesson!.lesson_content?.quizzes![0]
                                  .questions![contQuestionQuiz]
                              }
                              subject_id={individualLesson?.subject_id}
                            ></NewQuestions>
                          )}
                        </div>

                        {!(
                          individualLesson?.lesson_type === "literacy" &&
                          selectedKeyLiteracy === "1"
                        ) && (
                          <div className={styles.feedbackContainer}>
                            <Feedback
                              alertFalse={alertFalse}
                              alertTrue={alertTrue}
                              alertMid={alertMid}
                            />
                          </div>
                        )}
                      </>
                    )
                  )}

                  <Drawer
                    placement="top"
                    onClose={onClose}
                    open={visible}
                    closable={false}
                    maskClosable={false}
                    className="drawerVideoQuiz"
                    height={"90vh"}
                  >
                    <div className={styles.containerVideoQuiz}>
                      {individualLesson.lesson_content?.videos &&
                        individualLesson.lesson_content?.videos?.length > 0 &&
                        individualLesson.lesson_content?.videos[0]?.quizzes &&
                        individualLesson.lesson_content?.videos[0]?.quizzes
                          .length > 0 &&
                        individualLesson.lesson_content?.videos[0]?.quizzes[
                          contQuizzVideo
                        ] &&
                        individualLesson.lesson_content?.videos[0]?.quizzes[
                          contQuizzVideo
                        ]?.quiz && (
                          <NewQuestions
                            answers={answers}
                            setAnswer={setAnswer}
                            stateNext={stateNext}
                            setActiveIndex={setActiveIndex}
                            activeIndex={activeIndex}
                            content={
                              individualLesson.lesson_content?.videos![0]
                                .quizzes![contQuizzVideo].quiz!.questions![
                                contQuestionsVideo
                              ]
                            }
                            subject_id={individualLesson?.subject_id}
                          ></NewQuestions>
                        )}
                      <div className={styles.feedbackContainer}>
                        <Feedback
                          alertFalse={alertFalse}
                          alertTrue={alertTrue}
                          alertMid={alertMid}
                        />
                      </div>
                    </div>

                    {(individualLesson?.lesson_type !== "literacy" ||
                      selectedKeyLiteracy === "2") && (
                      <div className={styles.footerRelate}>
                        <button
                          className={stylesBotonNextNewQuestionsVideo()}
                          onClick={() => {
                            stateNext
                              ? functionSecondStepVideoQuiz()
                              : functionContinuarQuestionsVideo();
                          }}
                        >
                          {stateNext
                            ? individualLesson.subject_id === 16
                              ? "CONTINUE"
                              : "CONTINUAR"
                            : individualLesson.subject_id === 16
                            ? "CHECK"
                            : "COMPROBAR"}
                        </button>
                      </div>
                    )}
                  </Drawer>

                  {(individualLesson?.lesson_type !== "literacy" ||
                    selectedKeyLiteracy === "2") &&
                    !isVerticalMobile && (
                      <div className={styles.footerRelate}>
                        <button
                          ref={buttonRef}
                          className={stylesBotonNext()}
                          onClick={async () => {
                            if (stateNext) {
                              functioSecondStepSetState();
                              await functionSecondStep();
                            } else {
                              functionFirstStep();
                            }
                          }}
                          onKeyDown={(event) => handleKeyDown(event)}
                        >
                          {stateNext || videoContinuar
                            ? individualLesson.subject_id === 16
                              ? "CONTINUE"
                              : "CONTINUAR"
                            : individualLesson.subject_id === 16
                            ? "CHECK"
                            : "COMPROBAR"}
                        </button>
                      </div>
                    )}
                  {individualLesson?.lesson_type === "literacy" &&
                    selectedKeyLiteracy === "1" &&
                    !isVerticalMobile && (
                      <div className={styles.footerRelate}>
                        <button
                          ref={buttonRef}
                          className={styles.botonNormal}
                          onClick={() => {
                            setSelectedKeyLiteracy("2");
                          }}
                          onKeyDown={(event) => handleKeyDown(event)}
                        >
                          {individualLesson.subject_id === 16
                            ? "CONTINUE"
                            : "CONTINUAR"}
                        </button>
                      </div>
                    )}
                </>
              </div>
            )}
        </>
      )}
      {seeInstructions && (
        <DrawerLessonStudent
          origin="extra_points"
          estilos={"estilosIntruc"}
          data={individualLesson}
          onCloseLesson={handleInstructions}
          visibleLesson={seeInstructions}
        />
      )}
      <ModalLinkWrong open={openModalLinkWrong}></ModalLinkWrong>
      <ModalError
        onCloseModalDelete={onCloseModalDelete}
        open={openModalError}
      ></ModalError>
      <ModalConexion isOnline={!isOnline}></ModalConexion>
    </>
  );
};

export default NewQuizz;
