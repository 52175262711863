import { FC, useState, useEffect } from "react";
import styles from "./lessons.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
// import { gradeSelectEnglishOptions, gradeSelectOptions } from "./Utils";
import { Select } from "antd";
import { Loader, Searchbar } from "../Commons";
import { LayoutReading } from "./LayaoutReading";
import useDebounce from "../../hooks/useDebounce";
import UseGetLiteracyLessonsSearch from "../../api/useGetLiteracyLessonSearch";
import { getLiteracyLessonsSearch_getLiteracyLessonsSearch as PropsLiteracys } from "../../__generated__/gql-types/getLiteracyLessonsSearch";
import useUserDb from "../../UserProvider/UserProvider";
import { gradeSelectEnglishOptions, gradeSelectOptions } from "./Utils";

const Literacy: FC = () => {
  const navigate = useNavigate();
  const getLiteracyLessonSearch = UseGetLiteracyLessonsSearch();
  const { userAuthDb } = useUserDb();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gradeParams = queryParams.get("grade");
  const inputParams = queryParams.get("search");
  const subjectParams = queryParams.get("subject");
  const lessonIdParams = queryParams.get("lessonId");

  const firstSubject = subjectParams
    ? subjectParams
    : userAuthDb?.visible_spanish_language
    ? "2"
    : userAuthDb?.visible_english_language
    ? "16"
    : "2";
  const userRole = userAuthDb && userAuthDb?.role;

  const userGrades = userAuthDb?.user_grade;

  let gradesSorted: number[] = [];
  if (userGrades && userGrades.length > 0) {
    userGrades.map((a) => gradesSorted.push(a));
  } else {
    gradesSorted.push(6);
  }
  gradesSorted.sort((a, b) => a - b);

  const userGrade =
    (userAuthDb?.role === "student" &&
      userAuthDb?.user_grade &&
      userAuthDb.user_grade[0]) ||
    gradesSorted[0];

  const [isLoading, setIsLoading] = useState(false);
  const [grade, setGrade] = useState<string>(
    userRole === "student"
      ? userGrade?.toString()
      : userRole === "teacher" || userRole === "coordinator"
      ? `${gradesSorted[0]}`
      : gradeParams ?? "6"
  );

  const [lessonId, setLessonId] = useState<string>(lessonIdParams ?? "");
  const [searchInput, setSearchInput] = useState<string>(inputParams ?? "");
  const [literacys, setLiteracys] = useState<PropsLiteracys[] | null>(null);
  const [subjectId, setSubjectId] = useState<string>(firstSubject);
  const deb = useDebounce(searchInput ?? "", 1200);
  const handleChangeGrade = (values: string[]) => {
    setGrade(values[0]);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getLiteracyLessonSearch({
          getLessonsSearchArgs: {
            grade_ids: grade ? [Number(grade)] : null,
            search: deb,
            subject_ids: subjectId ? [Number(subjectId)] : null,
          },
        });
        setLiteracys(response);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    })();
  }, [getLiteracyLessonSearch, grade, deb, subjectId]);

  useEffect(() => {
    const nuevaUrl = `/literacy?grade=${grade}&subject=${subjectId}&search=${searchInput}&lessonId=${lessonId}`;
    navigate(nuevaUrl);
  }, [navigate, grade, searchInput, subjectId, lessonId]);

  return (
    <div className={styles.containerLesson}>
      <div className={styles.filterRow}>
        {userAuthDb?.schools[0].view_reading_spanish &&
          userAuthDb?.schools[0].view_reading_english && (
            <>
              {/* Mostrar los botones solo si el rol es teacher y ambos booleanos están en true */}
              {(userAuthDb.role === "teacher" ||
                userAuthDb?.role === "coordinator") &&
                userAuthDb.visible_spanish_language &&
                userAuthDb.visible_english_language && (
                  <>
                    <button
                      name="2"
                      className={
                        subjectId === "2" ? styles.itemSelected : styles.items
                      }
                      onClick={() => {
                        setSubjectId("2");
                      }}
                    >
                      Español
                    </button>

                    <button
                      name="16"
                      className={
                        subjectId === "16" ? styles.itemSelected : styles.items
                      }
                      onClick={() => {
                        setSubjectId("16");
                      }}
                    >
                      Inglés
                    </button>
                  </>
                )}

              {userAuthDb.role === "student" && (
                <>
                  <button
                    name="2"
                    className={
                      subjectId === "2" ? styles.itemSelected : styles.items
                    }
                    onClick={() => {
                      setSubjectId("2");
                    }}
                  >
                    Español
                  </button>

                  <button
                    name="16"
                    className={
                      subjectId === "16" ? styles.itemSelected : styles.items
                    }
                    onClick={() => {
                      setSubjectId("16");
                    }}
                  >
                    Inglés
                  </button>
                </>
              )}
            </>
          )}

        {subjectId === "16" ? (
          <Select
            // defaultValue={grade ? [grade] : ["6"]}
            value={[grade]}
            className={styles.selectGradeNem}
            onChange={handleChangeGrade}
            options={gradeSelectEnglishOptions(subjectId)}
          />
        ) : (
          <Select
            value={[grade]}
            className={styles.selectGradeNem}
            onChange={handleChangeGrade}
            options={gradeSelectOptions(subjectId)}
          />
        )}
        <Searchbar
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          personalStyles={"reading"}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <LayoutReading
          literacys={literacys}
          lessonId={lessonId}
          setLessonId={setLessonId}
        />
      )}
    </div>
  );
};

export default Literacy;
