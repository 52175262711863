import { useEffect, useState } from "react";
import styles from "./detailsHomeworkDrawer.module.scss";
import { toLocalDate, hasDatePassed } from "../../../utils/functions";

import deleteIcon from "../../../assets/icons/delete_trash.svg";
import copiedIcon from "../../../assets/icons/teacher/copiedIcon.svg";

// Components
import { Drawer, Skeleton } from "antd";
import DrawerLessonNew from "../../Lessons/Drawer/DrawerLessonNew";
import { TaskDescription } from "./TaskDescription";
import {
  notificationNegative,
  NotificationPositive,
} from "../../Shared/Notifacation/NotificationLuca";

// Apis
import UseGetLessonsById from "../../../api/useGetLessonsById";
import UseDeleteHomework from "../../../api/useDeleteHomework";
import UseGetHomeworkById from "../../../api/useGetHomeworksById";

// Types
import { getHomeworkById_getHomeworkById } from "../../../__generated__/gql-types/getHomeworkById";
import { getLessonById_getLessonById } from "../../../__generated__/gql-types/getLessonById";
import { DownloadStudentsHomeworksResultsAsExcel } from "./DownloadStudentsHomeworkResultsAsExcel/DownloadStudentsHomeworksResultsAsExcel";
import AssingHomeworkDrawer from "../../Homework/Drawer/AssingHomeworkDrawer";
import { ModalDelete } from "../../Shared/ModalDelete";
import useIsMobile from "../../../hooks/useIsMobile";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DependencyRefresh } from "../../../redux/store/store";
import { DrawerInteligentTeacher } from "./StudentsStatusTable/DrawerInteligentTeacher";
import { DrawerConversation } from "./DrawerConversation";
import { setDependencyRefresh } from "../../../redux/slices/dependencyRefresh";
import TitleDetailsHomework from "./TitleDetailsHomework/TitleDetailsHomework";
import { DrawerStudentResponse } from "./TaskDescription/DrawerStudentResponse";
import { useLocation, useNavigate } from "react-router-dom";

interface IDrawerHomeworkProps {
  visibleTask: boolean;
  taskId: string | null;
  onCloseTask: () => void;
  back: string;
}

const DetailsHomeworkDrawer = ({
  onCloseTask,
  visibleTask,
  taskId,
  back,
}: IDrawerHomeworkProps) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const deleteHomework = UseDeleteHomework();
  const getLessonsById = UseGetLessonsById();
  const getHomeworkById = UseGetHomeworkById();
  const queryParams = new URLSearchParams(location.search);
  const taskIdParams = queryParams.get("taskId");

  const [task, setTask] = useState<getHomeworkById_getHomeworkById | null>(
    null
  );
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleDrawerConversation, setVisibleDrawerConversation] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleAssing, setVisibleAssing] = useState(false);
  const [visible, setVisible] = useState(taskIdParams ? true : false);
  const [visibleModalStudentResponse, setVisibleModalStudentResponse] =
    useState(false);
  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);
  const [lessonId, setLessonId] = useState<string>("");
  const [uploadHomework, setUploadHomework] =
    useState<getHomeworkById_getHomeworkById>();
  const [userIdSetted, setUserIdSetted] = useState<number | null>(null);
  const HourFormatting = (taskRes: getHomeworkById_getHomeworkById | null) => {
    return {
      ...taskRes,
      start_date: toLocalDate(taskRes?.start_date),
      end_date: toLocalDate(taskRes?.end_date),
    } as getHomeworkById_getHomeworkById | null;
  };

  const reassingHomeworkButtonText = hasDatePassed(task?.end_date)
    ? "Reasignar"
    : "Editar Asignación";

  useEffect(() => {
    (async () => {
      const taskRes = await getHomeworkById({ homeworkId: taskId! });
      const FixedHourTask = HourFormatting(taskRes);
      setTask(FixedHourTask);
    })();
  }, [taskId, getHomeworkById, dependencyRefresh]);

  useEffect(() => {
    setLessonId(task?.lessons ? task?.lessons[0]?.id : "");
  }, [task?.lessons]);

  useEffect(() => {
    (async () => {
      const individualLessonRes = await getLessonsById({
        lessonId: `${lessonId}`,
      });
      setIndividualLesson(individualLessonRes);
    })();
  }, [lessonId, getLessonsById, taskId]);

  const onClose = () => {
    setVisibleAssing(false);
  };

  const onCloseLesson = () => {
    setVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalLesson = () => {
    if (task?.id) {
      const params = new URLSearchParams(location.search);
      params.set("taskId", task?.id);
      navigate(`?${params.toString()}`);
    }
    setVisible(true);
  };

  const showModalStudentResponseVisible = () => {
    setVisibleModalStudentResponse(true);
  };

  const deleteHomeworkModal = async () => {
    try {
      await deleteHomework({ homeworkId: taskId!.toString() });
      NotificationPositive("¡Estupendo! La tarea ha sido eliminada con éxito.");
      dispatch(setDependencyRefresh(!dependencyRefresh)); // Hacer Refresh
    } catch (error) {
      notificationNegative();
    }
    handleCancel();
    onCloseTask();
  };

  const copiarAlPortapapeles = () => {
    NotificationPositive("Enlace copiado correctamente en tu portapapeles ");
    navigator.clipboard
      .writeText(
        `${window.location.origin}/task/${
          task?.lessons![0].id
        }?homework=${taskId}`
      )
      .then(() => {});
  };

  return (
    <>
      <Drawer
        width={500}
        placement={isMobile ? "bottom" : "right"}
        height={isMobile ? "500px" : ""}
        title={
          <TitleDetailsHomework
            task={task}
            individualLesson={individualLesson}
            onCloseTask={onCloseTask}
          />
        }
        closable={false}
        onClose={onCloseTask}
        open={visibleTask}
        footerStyle={{ zIndex: 100 }}
        styles={{
          header: { padding: isMobile ? "" : "8px 16px 8px 24px" },

          wrapper: !isMobile
            ? {
                width: "calc(100% - 300px)",
                minWidth: "780px",
                maxWidth: "915px",
              }
            : {},
        }}
        footer={
          task ? (
            <div className={styles.footerDetail}>
              <img
                src={deleteIcon}
                alt="deleteIcon"
                onClick={showModal}
                className={styles.deleteTaskImg}
              />

              <div className={styles.rightButtons}>
                <button
                  className={`${styles.taskButtonDelete} ${styles.footerButton}`}
                  onClick={copiarAlPortapapeles}
                >
                  Copiar enlace
                  <img src={copiedIcon} alt="downloader" />
                </button>
                {!isMobile && (
                  <DownloadStudentsHomeworksResultsAsExcel
                    dataToRender={task as getHomeworkById_getHomeworkById}
                    classRoom={task?.classroom}
                  />
                )}

                <button
                  className={`${styles.taskButtonMision} ${styles.footerButton}`}
                  onClick={() => {
                    task && setUploadHomework(task);
                    setVisibleAssing(true);
                  }}
                >
                  {reassingHomeworkButtonText}
                </button>
              </div>
            </div>
          ) : (
            <Skeleton active paragraph={{ rows: 1 }} style={{ margin: 3 }} />
          )
        }
      >
        {task ? (
          <TaskDescription
            task={task}
            showModalLesson={showModalLesson}
            setVisibleDrawerConversation={setVisibleDrawerConversation}
            setVisibleModalStudentResponse={setVisibleModalStudentResponse}
            setVisibleDrawer={setVisibleDrawer}
            setUserIdSetted={setUserIdSetted}
          />
        ) : (
          <Skeleton active paragraph={{ rows: 4 }} style={{ margin: 0 }} />
        )}
        <ModalDelete
          modalDelete={isModalVisible}
          onCloseModalDelete={handleCancel}
          deleteHomeworkModal={deleteHomeworkModal}
        />
      </Drawer>

      {visibleAssing && (
        <AssingHomeworkDrawer
          incomingSelectedLesson={null}
          uploadHomework={uploadHomework}
          onCloseTask={onCloseTask}
          visible={visibleAssing}
          onClose={onClose}
        ></AssingHomeworkDrawer>
      )}

      {visible && taskId && (
        <DrawerLessonNew
          back={back}
          taskId={taskId}
          onClose={onCloseLesson}
          visible={visible}
          individualLesson={individualLesson}
        />
      )}
      {visibleDrawer && task?.id && (
        <DrawerInteligentTeacher
          userId={userIdSetted}
          taskId={task?.id}
          setVisibleDrawer={setVisibleDrawer}
          visibleDrawer={visibleDrawer}
        />
      )}

      {visibleDrawerConversation && task?.id && (
        <DrawerConversation
          userId={userIdSetted}
          taskId={task?.id}
          setVisibleDrawerConversation={setVisibleDrawerConversation}
          visibleDrawerConversation={visibleDrawerConversation}
        />
      )}

      {visibleModalStudentResponse && userIdSetted && Number(task?.id) && (
        <DrawerStudentResponse
          setVisibleModalStudentResponse={setVisibleModalStudentResponse}
          visibleModalStudentResponse={visibleModalStudentResponse}
          taskId={Number(task?.id)}
          userId={userIdSetted}
        />
      )}
    </>
  );
};

export default DetailsHomeworkDrawer;
