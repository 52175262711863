import { FC, useState, useEffect, FormEvent } from "react";
import { Drawer, Space, Button, Checkbox, Select } from "antd";
import styles from "./assingHomeworkDrawer.module.scss";
import cross from "../../../assets/images/cross.svg";
import attach from "../../../assets/icons/dock.svg";
import check from "../../../assets/icons/Checkbox.svg";

import searchIcon from "../../../assets/icons/searchIcon2.svg";
import { Collapse } from "antd";

import { Input } from "antd";
import moment from "moment";
import UseCreateHomework from "../../../api/useCreateHomework";
// Api && Types
import { HomeworkInput } from "../../../__generated__/gql-types/globalTypes";
import { toUtcDate } from "./../../../utils/functions";

import {
  notificationNegative,
  NotificationPositive,
} from "../../Shared/Notifacation/NotificationLuca";
import { HeaderDrawerMobile } from "../../Mobile/Shared/HeaderDrawerMobile";
import FileUploader from "./FileUploader";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { AssingHomeworkDrawerProps } from "./utils";
import useUpdateHomework from "../../../api/useUpdateHomework";
import { getClassroomsByTeacher_getClassroomsByTeacher } from "../../../__generated__/gql-types/getClassroomsByTeacher";
import useTeacherAssignHomeworkActionEvent from "../../../api/useTeacherAssignHomeworkActionEvent";
import useTeacherAssignEvaluationActionEvent from "../../../api/useTeacherAssignEvaluationActionEvent";
import useTeacherAssignHomeworkNEMActionEvent from "../../../api/useTeacherAssignHomeworkNEMActionEvent";
import useIsMobile from "../../../hooks/useIsMobile";
import useTeacherAssignHomeworkRedactionActionEvent from "../../../api/useTeacherAssignHomeworkRedactionActionEvent";
import { AssignClassroom } from "./AssignClassroom";
import UseClassroomsByTeacher from "../../../api/useGetClassroomsByTeacher";
import dayjs from "dayjs";
import { AssingDate } from "./AssingDate";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DependencyRefresh } from "../../../redux/store/store";
import { setDependencyRefresh } from "../../../redux/slices/dependencyRefresh";
import { Loader } from "../../Commons";
import { AssignCourseLessons } from "./AssignCourseLessons";
import { ReAssingAlumns } from "./ReAssingAlumns";
import useUpdateDashboardVisibleHomework from "../../../api/useupdateDashboardVisibleHomework";
import useTeacherAssignHomeworkConvAIActionEvent from "../../../api/useTeacherAssignHomeworkConvAIActionEvent";
import useTeacherAssignHomeworkLiteracyActionEvent from "../../../api/useTeacherAssignHomeworkLiteracyActionEvent";

const AssingHomeworkDrawer: FC<AssingHomeworkDrawerProps> = ({
  onClose,
  setIsLoading,
  visible,
  uploadHomework,
  onCloseDrawerLesson,
  incomingSelectedLesson,
  onCloseTask,
}) => {
  const isMobile = useIsMobile();
  const createHomework = UseCreateHomework();
  const updateHomework = useUpdateHomework();
  const getClassroomsByTeacher = UseClassroomsByTeacher();
  const dispatch = useAppDispatch();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const updateDashboard = useUpdateDashboardVisibleHomework();

  const teacherAssignHomeworkActionEvent =
    useTeacherAssignHomeworkActionEvent();

  const teacherAssignHomeworkConvAIActionEvent =
    useTeacherAssignHomeworkConvAIActionEvent();

  const teacherAssignHomeworkLiteracyActionEvent =
    useTeacherAssignHomeworkLiteracyActionEvent();

  const teacherAssignHomeworkRedactionActionEvent =
    useTeacherAssignHomeworkRedactionActionEvent();

  const teacherAssignEvaluationActionEvent =
    useTeacherAssignEvaluationActionEvent();

  const teacherAssignHomeworkNEM = useTeacherAssignHomeworkNEMActionEvent();
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const timeFormat = "HH:mm";
  const dateFormat = "DD-MM-YYYY";
  let oldHomeworkStartDate = uploadHomework?.start_date?.split(" ");
  let oldHomeworkEndDate = uploadHomework?.end_date?.split(" ");

  const hour =
    uploadHomework && oldHomeworkStartDate
      ? uploadHomework.status !== "finished"
        ? oldHomeworkStartDate[1]
        : moment().format(timeFormat).toString()
      : moment().format(timeFormat).toString();
  const date =
    uploadHomework && oldHomeworkStartDate
      ? uploadHomework.status !== "finished"
        ? oldHomeworkStartDate[0]
        : dayjs().format(dateFormat)
      : dayjs().format(dateFormat);

  const endDateInitial =
    uploadHomework && oldHomeworkEndDate
      ? uploadHomework.status !== "finished"
        ? oldHomeworkEndDate[0]
        : dayjs().add(2, "day").format(dateFormat).toString()
      : dayjs().add(2, "day").format(dateFormat).toString();
  const endDayHour =
    uploadHomework && oldHomeworkEndDate
      ? uploadHomework.status !== "finished"
        ? oldHomeworkEndDate[1]
        : moment().format("23:59")
      : moment().format("23:59");
  const [selectedCourseLessons, setSelectedCourseLessons] = useState<number[]>([
    Number(incomingSelectedLesson?.id),
  ]);
  const [alumnsReAssing, setAlumnsReAssing] = useState(
    uploadHomework?.students_grades
      ? uploadHomework?.students_grades
          .filter((alumn) => !alumn.delivered)
          .concat(
            uploadHomework?.students_grades.filter(
              (alumn) =>
                (alumn.high_score && alumn.high_score < 6) ||
                alumn.high_score === 0
            )
          )
      : []
  );
  const [fileAws, setFilesAws] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [sendEmail, setsendEmail] = useState(false);
  const [startDate, setStartDate] = useState<string | dayjs.Dayjs>(date);
  const [startTime, setStartTime] = useState<string | dayjs.Dayjs>(hour);
  const [endDate, setEndDate] = useState<string | dayjs.Dayjs>(endDateInitial);
  const [endTime, setEndTime] = useState<string | dayjs.Dayjs>(endDayHour);
  const [inputValue, setInputValue] = useState("");
  const [activeKey, setActiveKey] = useState<number>(
    uploadHomework?.status === "finished" ? 7 : 4
  );
  const [classRooms, setClassRooms] = useState<
    getClassroomsByTeacher_getClassroomsByTeacher[] | null
  >(null);
  const [idAlumns, setIdAlumns] = useState<CheckboxValueType[]>(
    uploadHomework?.students_grades?.map((e: { student_id: any }) =>
      e.student_id!.toString()
    ) ?? []
  );
  const [selectedLesson, setSelectedLesson] = useState(
    uploadHomework && uploadHomework.lessons
      ? uploadHomework.lessons[0]
      : incomingSelectedLesson
  );
  const [selectedClassrooms, setSelectedClassrooms] = useState<
    getClassroomsByTeacher_getClassroomsByTeacher[] | null
  >(null);

  const currentURL = window.location.href;
  const courseId =
    (incomingSelectedLesson && incomingSelectedLesson.course_id) ?? 0;

  const [startDateForDisabledEndDate, setStartDateForDisabledEndDate] =
    useState<string | dayjs.Dayjs>(dayjs().format("YYYY-MM-DD"));

  const [homework, setHomework] = useState<HomeworkInput>(
    uploadHomework
      ? {
          tries: uploadHomework.tries,
          description: uploadHomework.description,
          title: uploadHomework.title,
          start_date: `${startDate} ${startTime}`,
          end_date: `${endDate} ${endTime}`,
          classroom_id: [Number(uploadHomework?.classroom?.id)],
          lessons: [Number(uploadHomework!.lessons![0].id!)],
          needs_file_evidence: uploadHomework.needs_file_evidence,
        }
      : {
          tries: null,
          description: "",
          title: "",
          start_date: `${startDate} ${startTime}`,
          end_date: `${endDate} ${endTime}`,
          classroom_id: [],
          lessons: selectedCourseLessons,
          needs_file_evidence:
            incomingSelectedLesson?.lesson_content.redaction?.needs_evidence ??
            false,
        }
  );

  const isFormComplete = (homework: HomeworkInput): boolean => {
    return homework?.classroom_id &&
      homework?.end_date?.length === 16 &&
      homework?.start_date?.length === 16 &&
      homework?.lessons &&
      homework.lessons[0] !== null &&
      !isNaN(homework.lessons[0])
      ? true
      : false;
  };

  const programPanelTitle = (
    startDate: string | dayjs.Dayjs,
    endDate: string | dayjs.Dayjs
  ): string => {
    return dayjs().format(dateFormat).toString() === endDate &&
      dayjs().format(dateFormat).toString() === startDate
      ? `Hoy ${startTime} - Hoy ${endTime}`
      : dayjs().format(dateFormat).toString() === endDate
      ? `${startDate} ${startTime} - Hoy ${endTime}`
      : dayjs().format(dateFormat).toString() === startDate
      ? `Hoy ${startTime} - ${endDate} ${endTime}`
      : startDate
      ? `${startDate} ${startTime} - ${endDate}  ${endTime}`
      : "--";
  };

  const handleSubmit = async () => {
    setSelectedLesson(null);

    setIsLoading && setIsLoading(true);
    onCloseDrawerLesson && onCloseDrawerLesson();
    const classroomsIds = selectedClassrooms?.map((classroom) =>
      Number(classroom.id)
    );
    const usersSelected =
      selectedClassrooms?.length !== 1
        ? []
        : idAlumns.length === selectedClassrooms[0]!.group!.users!.length!
        ? []
        : idAlumns.map((id) => Number(id));

    if (uploadHomework) {
      try {
        if (uploadHomework.status !== "finished") {
          await updateHomework({
            homeworkId: `${uploadHomework.id}`,
            homeworkInput: {
              ...homework,
              users: usersSelected,
              start_date: toUtcDate(`${startDate} ${startTime}`).replaceAll(
                "-",
                "/"
              ),
              end_date: toUtcDate(`${endDate} ${endTime}`).replaceAll("-", "/"),
            },
          });

          NotificationPositive(
            "¡Estupendo! La tarea se ha modificado con éxito."
          );
        } else {
          //este bloque es para reasignar la tarea
          uploadHomework?.id &&
            (await updateDashboard({
              homeworkId: Number(uploadHomework?.id),
              visible: false,
            }));
          let userReasing = alumnsReAssing.map((alumn) => alumn.student_id);
          const response = await createHomework({
            ...homework,
            classroom_id: classroomsIds,
            users: userReasing,
            send_email: sendEmail,
            date_to_email: `${endDate} ${endTime}`,
            start_date: toUtcDate(`${startDate} ${startTime}`).replaceAll(
              "-",
              "/"
            ),
            end_date: toUtcDate(`${endDate} ${endTime}`).replaceAll("-", "/"),
          });

          const lessonLanguage =
            selectedLesson?.subject?.id === "16" ? "english" : "spanish";
          const lessonType = selectedLesson?.lesson_type;
          const contentId = Number(selectedLesson?.id);
          const startDateFormatted = toUtcDate(
            `${startDate} ${startTime}`
          ).replaceAll("-", "/");
          if (lessonType === "evaluation") {
            teacherAssignEvaluationActionEvent({
              contentId: contentId,
              startDate: startDateFormatted,
              reasign: true,
            });
          } else if (lessonType === "redaction") {
            teacherAssignHomeworkRedactionActionEvent({
              contentId: contentId,
              startDate: startDateFormatted,
              reasign: true,
            });
          } else if (lessonType === "literacy") {
            teacherAssignHomeworkLiteracyActionEvent({
              contentId: contentId,
              startDate: startDateFormatted,
              language: lessonLanguage,
              reasign: true,
            });
          } else if (lessonType === "conversation") {
            teacherAssignHomeworkConvAIActionEvent({
              contentId: contentId,
              startDate: startDateFormatted,
              reasign: true,
            });
          } else {
            teacherAssignHomeworkActionEvent({
              contentId: contentId,
              startDate: startDateFormatted,
              reasign: true,
            });
          }
          response &&
            NotificationPositive(
              "¡Estupendo! La tarea ha sido asignada con éxito."
            );
        } //este bloque es para reasignar la tarea
      } catch (error) {
        String(error).includes("individual")
          ? notificationNegative(
              "¡Ups! Ya tienes una tarea en curso con esa lección asignada. Por favor, elimínala o espera a que finalice."
            )
          : notificationNegative();
      }
    } else {
      try {
        const response = await createHomework({
          ...homework,
          classroom_id: classroomsIds,
          lessons: selectedCourseLessons,
          users: usersSelected,
          send_email: sendEmail,
          date_to_email: `${endDate} ${endTime}`,
          start_date: toUtcDate(`${startDate} ${startTime}`).replaceAll(
            "-",
            "/"
          ),
          end_date: toUtcDate(`${endDate} ${endTime}`).replaceAll("-", "/"),
        });
        response &&
          NotificationPositive(
            "¡Estupendo! La tarea ha sido asignada con éxito."
          );

        const lessonLanguage =
          incomingSelectedLesson?.subject_id === 16 ? "english" : "spanish";
        if (currentURL.includes("nem")) {
          if (incomingSelectedLesson?.lesson_type === "evaluation") {
            teacherAssignEvaluationActionEvent({
              contentId: Number(incomingSelectedLesson?.id),
              startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
                "-",
                "/"
              ),
            });
          } else if (incomingSelectedLesson?.lesson_type === "redaction") {
            teacherAssignHomeworkRedactionActionEvent({
              contentId: Number(incomingSelectedLesson?.id),
              startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
                "-",
                "/"
              ),
            });
          } else if (incomingSelectedLesson?.lesson_type === "literacy") {
            teacherAssignHomeworkLiteracyActionEvent({
              contentId: Number(incomingSelectedLesson?.id),
              startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
                "-",
                "/"
              ),
              language: lessonLanguage,
            });
          } else if (incomingSelectedLesson?.lesson_type === "conversation") {
            teacherAssignHomeworkConvAIActionEvent({
              contentId: Number(incomingSelectedLesson?.id),
              startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
                "-",
                "/"
              ),
            });
          } else {
            teacherAssignHomeworkNEM({
              contentId: Number(incomingSelectedLesson?.id),
              startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
                "-",
                "/"
              ),
            });
          }
        } else if (incomingSelectedLesson?.lesson_type === "evaluation") {
          teacherAssignEvaluationActionEvent({
            contentId: Number(incomingSelectedLesson?.id),
            startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
              "-",
              "/"
            ),
          });
        } else if (incomingSelectedLesson?.lesson_type === "redaction") {
          teacherAssignHomeworkRedactionActionEvent({
            contentId: Number(incomingSelectedLesson?.id),
            startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
              "-",
              "/"
            ),
          });
        } else if (incomingSelectedLesson?.lesson_type === "literacy") {
          teacherAssignHomeworkLiteracyActionEvent({
            contentId: Number(incomingSelectedLesson?.id),
            startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
              "-",
              "/"
            ),
            language: lessonLanguage,
          });
        } else if (incomingSelectedLesson?.lesson_type === "conversation") {
          teacherAssignHomeworkConvAIActionEvent({
            contentId: Number(incomingSelectedLesson?.id),
            startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
              "-",
              "/"
            ),
          });
        } else {
          teacherAssignHomeworkActionEvent({
            contentId: Number(incomingSelectedLesson?.id),
            startDate: toUtcDate(`${startDate} ${startTime}`).replaceAll(
              "-",
              "/"
            ),
          });
        }
      } catch (error) {
        console.log(error);
        const errorMessages = {
          grupal: {
            programed:
              "¡Ups! ya tienes una tarea programada para esa lección en alguna de las clases que seleccionaste. Si quieres, puedes editar la asignación desde tu lista de tareas.",
            inCourse:
              "¡Ups! ya existe la tarea en curso en alguna de las clases que seleccionaste, por favor verifique antes de crear otra.",
          },
          individual: {
            programed:
              "¡Ups! ya tienes una tarea programada para esa lección. Si quieres, puedes editar la asignación desde tu lista de tareas.",
            inCourse:
              "¡Ups! ya tienes una tarea en curso con esa lección asignada. Por favor, elimínala o espera a que finalice.",
          },
          default:
            "¡Ups! parece que no tienes ninguna clase seleccionada. Por favor seleccione una clase.",
        };

        // Determina el tipo de error
        const errorType = String(error).includes("grupal")
          ? "grupal"
          : String(error).includes("individual")
          ? "individual"
          : "default";

        // Determina el estado de la tarea
        const taskStatus = String(error).includes("programed")
          ? "programed"
          : "inCourse";

        // Selecciona el mensaje de error correspondiente
        const message =
          errorType === "default"
            ? errorMessages.default
            : errorMessages[errorType][taskStatus];

        // Muestra la notificación
        notificationNegative(message);
      }
    }
    dispatch(setDependencyRefresh(!dependencyRefresh)); // Hacer Refresh
    onClose();
  };

  const onCloseDrawer = () => {
    setSelectedLesson(null);
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    onClose();
  };

  const handleCheckAll = () => {
    if (selectedClassrooms?.length === 1) {
      let newArray: CheckboxValueType[] = [];
      selectedClassrooms[0]?.group?.users &&
        selectedClassrooms[0]?.group?.users.map((user) => {
          newArray.push(user.id);
          setIdAlumns(newArray);
        });
    }
  };
  const handleCancelAll = () => {
    setIdAlumns([]);
  };

  const handleCheck = (value: string, e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setIdAlumns([...idAlumns, value]);
    } else {
      setIdAlumns(idAlumns.filter((item) => item !== value));
    }
  };
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await getClassroomsByTeacher({
          getClassroomsByTeacherIdInput: {
            searchArgs: null,
          },
        });

        if (incomingSelectedLesson) {
          const filteredClassromsSubject = response?.filter((classroom) => {
            const lessonSubjectId =
              incomingSelectedLesson.subject_id === 17
                ? 2
                : incomingSelectedLesson.subject_id;
            return (
              classroom.program?.subject?.id === lessonSubjectId?.toString()
            );
          });

          filteredClassromsSubject && setClassRooms(filteredClassromsSubject);

          filteredClassromsSubject?.length === 1 &&
            setSelectedClassrooms(filteredClassromsSubject);
          setActiveKey(
            uploadHomework
              ? 4
              : filteredClassromsSubject && filteredClassromsSubject?.length > 1
              ? 1
              : 4
          );
        }

        if (uploadHomework) {
          const filteredClassromsID = response?.filter(
            (classroom) => classroom.id === uploadHomework?.classroom?.id
          );

          setSelectedClassrooms(filteredClassromsID ?? null);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [
    getClassroomsByTeacher,
    uploadHomework?.classroom?.id,
    incomingSelectedLesson?.subject_id,
  ]);

  return (
    <Drawer
      title={
        isMobile ? (
          <HeaderDrawerMobile
            onClose={onClose}
            goBack={onCloseDrawer}
            title={
              uploadHomework
                ? "Volver a la Clase"
                : incomingSelectedLesson?.lesson_type === "evaluation"
                ? "Volver a la Evaluación"
                : "Volver a la Lección"
            }
          />
        ) : (
          <p style={{ cursor: "pointer" }} onClick={onClose}>
            {uploadHomework
              ? "Volver a la Clase"
              : incomingSelectedLesson?.lesson_type === "evaluation"
              ? "Volver a la Evaluación"
              : "Volver a la Lección"}{" "}
          </p>
        )
      }
      height={isMobile ? "500px" : ""}
      placement={isMobile ? "bottom" : "right"}
      open={visible}
      closable={false}
      bodyStyle={{ background: "rgba(237, 237, 237, 0.8)" }}
      extra={
        isMobile ? (
          <></>
        ) : (
          <Space>
            <img
              src={cross}
              alt=""
              className={styles.crossImg}
              onClick={() => {
                onCloseDrawer();
              }}
            />
          </Space>
        )
      }
      footer={
        <div className={styles.footer}>
          <FileUploader
            setHomework={setHomework}
            homework={homework}
            setFilesAws={setFilesAws}
            folder="luca2-backend"
            setActiveKey={setActiveKey}
          ></FileUploader>

          <Button
            disabled={!isFormComplete(homework)}
            className={styles.taskButton}
            style={
              isFormComplete(homework)
                ? {
                    backgroundColor: "rgb(255, 188, 65)",
                    borderRadius: "11px",
                    width: "130px",
                  }
                : {
                    backgroundColor: "rgba(189, 185, 185, 0.64)",
                    borderRadius: "11px",
                    width: "130px",
                  }
            }
            onClick={() => {
              onClose();
              uploadHomework?.status === "finished" &&
                onCloseTask &&
                onCloseTask();
              handleSubmit();
            }}
          >
            {uploadHomework
              ? uploadHomework.status !== "finished"
                ? "Confirmar"
                : "Asignar"
              : "Asignar"}
          </Button>
        </div>
      }
      className={styles.drawerContainer}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <Collapse
            activeKey={[activeKey]}
            bordered
            ghost
            style={isMobile ? { width: "95%" } : {}}
          >
            <Panel
              header={
                <div
                  className={styles.panelTitle}
                  onClick={() =>
                    activeKey === 1 ? setActiveKey(0) : setActiveKey(1)
                  }
                >
                  Clase{" "}
                  <div className={styles.panelItemTitle}>
                    {selectedClassrooms?.length === 1
                      ? selectedClassrooms[0].name
                      : "--"}
                  </div>{" "}
                </div>
              }
              extra={
                activeKey !== 1 ? (
                  <button
                    onClick={() =>
                      activeKey === 1 ? setActiveKey(0) : setActiveKey(1)
                    }
                    className={styles.modifyTitle}
                  >
                    {uploadHomework
                      ? ""
                      : homework.classroom_id
                      ? "Modificar"
                      : "Elegir"}
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      activeKey === 1 ? setActiveKey(0) : setActiveKey(1)
                    }
                    className={styles.modifyTitleWhite}
                  >
                    Modificar
                  </button>
                )
              }
              key={1}
              showArrow={false}
              className={styles.customPanelStyle}
            >
              <AssignClassroom
                selectedClassrooms={selectedClassrooms}
                setSelectedClassrooms={setSelectedClassrooms}
                classRooms={classRooms}
              />
            </Panel>

            {/* lesson panel */}
            <Panel
              forceRender={true}
              header={
                <div className={styles.panelTitle}>
                  Lección{" "}
                  <div className={styles.panelItemTitle}>
                    {selectedLesson ? selectedLesson.title : "--"}
                  </div>{" "}
                </div>
              }
              key={2}
              showArrow={false}
              className={styles.customPanelStyle}
            ></Panel>
            {uploadHomework?.status === "finished" && (
              <Panel
                header={
                  <div
                    className={styles.panelTitle}
                    onClick={() =>
                      activeKey === 7 ? setActiveKey(0) : setActiveKey(7)
                    }
                  >
                    Alumnos{" "}
                  </div>
                }
                extra={
                  <button
                    onClick={() =>
                      activeKey === 7 ? setActiveKey(0) : setActiveKey(7)
                    }
                    className={styles.modifyTitle}
                  >
                    {activeKey !== 7 && "Modificar"}
                  </button>
                }
                key={7}
                showArrow={false}
                style={{
                  borderRadius: "10px",
                }}
                className={styles.customPanelStyle}
              >
                <ReAssingAlumns
                  setActiveKey={setActiveKey}
                  totalAlumns={uploadHomework.students_grades ?? []}
                  setAlumnsReAssing={setAlumnsReAssing}
                  alumnsReAssing={alumnsReAssing}
                />
              </Panel>
            )}

            {/* related lessons panel */}
            {!uploadHomework && (
              <Panel
                forceRender={true}
                header={
                  <div
                    className={styles.panelTitle}
                    onClick={() =>
                      activeKey === 3 ? setActiveKey(0) : setActiveKey(3)
                    }
                  >
                    Asignar otras tareas de este curso{" "}
                  </div>
                }
                extra={
                  activeKey !== 3 ? (
                    <button
                      onClick={() =>
                        activeKey === 3 ? setActiveKey(0) : setActiveKey(3)
                      }
                      className={styles.modifyTitle}
                    >
                      Elegir
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        activeKey === 3 ? setActiveKey(0) : setActiveKey(3)
                      }
                      className={styles.modifyTitleWhite}
                    >
                      Modificar
                    </button>
                  )
                }
                key={3}
                showArrow={false}
                className={styles.customPanelStyle}
              >
                <AssignCourseLessons
                  courseId={courseId}
                  lessonId={Number(selectedLesson?.id)}
                  setSelectedCourseLessons={setSelectedCourseLessons}
                  selectedCourseLessons={selectedCourseLessons}
                ></AssignCourseLessons>
              </Panel>
            )}

            {/* program panel */}
            <Panel
              header={
                <div
                  className={styles.panelTitle}
                  onClick={() =>
                    activeKey === 4 ? setActiveKey(0) : setActiveKey(4)
                  }
                >
                  Programar
                  <div className={styles.panelItemTitle}>
                    {programPanelTitle(startDate, endDate)}
                  </div>
                </div>
              }
              extra={
                activeKey !== 4 ? (
                  <button
                    onClick={() =>
                      activeKey === 4 ? setActiveKey(0) : setActiveKey(4)
                    }
                    className={styles.modifyTitle}
                  >
                    Elegir
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      activeKey === 4 ? setActiveKey(0) : setActiveKey(4)
                    }
                    className={styles.modifyTitleWhite}
                  >
                    Modificar
                  </button>
                )
              }
              key={4}
              showArrow={false}
              className={styles.customPanelStyle}
            >
              <AssingDate
                endDate={endDate}
                endTime={endTime}
                setEndDate={setEndDate}
                setEndTime={setEndTime}
                setStartDate={setStartDate}
                startDateForDisabledEndDate={startDateForDisabledEndDate}
                setStartDateForDisabledEndDate={setStartDateForDisabledEndDate}
                setStartTime={setStartTime}
                startDate={startDate}
                startTime={startTime}
              ></AssingDate>
            </Panel>

            {/* Notification */}
            <Panel
              header={
                <div
                  className={styles.panelTitle}
                  onClick={() => setsendEmail(!sendEmail)}
                >
                  Notificación{" "}
                </div>
              }
              extra={
                <div
                  className={styles.panelTitleNotfication}
                  onClick={() => setsendEmail(!sendEmail)}
                >
                  <div style={{ marginRight: 10, marginTop: 7 }}>
                    <p className={styles.panelItemTitle}>
                      Enviar notificación por correo al alumno
                    </p>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <div className={sendEmail ? "" : styles.checkEvidence}>
                      {sendEmail && <img src={check} alt="" />}
                    </div>
                  </div>
                </div>
              }
              key={5}
              showArrow={false}
              style={{
                borderRadius: "10px",
              }}
              className={styles.customPanelStyle}
            ></Panel>

            {/* instructions panel */}
            <Panel
              header={
                <div
                  className={styles.panelTitle}
                  onClick={() =>
                    activeKey === 6 ? setActiveKey(0) : setActiveKey(6)
                  }
                >
                  Instrucciones{" "}
                  <div className={styles.panelItemTitle}>
                    {homework.description ? "" : "--"}{" "}
                  </div>{" "}
                </div>
              }
              extra={
                activeKey !== 6 ? (
                  <button
                    onClick={() =>
                      activeKey === 6 ? setActiveKey(0) : setActiveKey(6)
                    }
                    className={styles.modifyTitle}
                  >
                    Elegir
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      activeKey === 6 ? setActiveKey(0) : setActiveKey(6)
                    }
                    className={styles.modifyTitleWhite}
                  >
                    Elegir
                  </button>
                )
              }
              key={6}
              showArrow={false}
              style={{
                borderRadius: "10px",
              }}
              className={styles.customPanelStyle}
            >
              <div className={styles.labelInstructions}>Titulo</div>
              <input
                className={styles.inputTitle}
                name="title"
                onChange={(e) => {
                  setHomework({
                    ...homework,
                    title: e.target.value,
                  });
                }}
                defaultValue={
                  uploadHomework
                    ? homework.title ?? ""
                    : selectedLesson?.title ?? ""
                }
                placeholder="Titulo de la tarea"
              ></input>
              <div className={styles.labelInstructions}>Instrucciones</div>

              <TextArea
                rows={4}
                value={homework.description ?? ""}
                name="description"
                className={styles.labelInstructions}
                onChange={(e) => {
                  setHomework({
                    ...homework,
                    description: e.target.value,
                  });
                }}
                style={{ borderRadius: "10px", marginBottom: "15px" }}
                placeholder="Ej. Realizar los ejercicios sin consultar el libro (Opcional)"
              />

              <div
                className={
                  homework.needs_file_evidence
                    ? styles.containerEvidenceNeed
                    : styles.containerEvidence
                }
              >
                <div className={styles.containerTextEvidence}>
                  <div className={styles.titleEvidence}>Requiere evidencia</div>
                  <div className={styles.subTitleEvidence}>
                    Solicita a tus alumnos que adjunten un archivo para
                    completar la tarea
                  </div>
                </div>

                <div
                  className={
                    homework.needs_file_evidence ? "" : styles.checkEvidence
                  }
                  onClick={() => {
                    setHomework({
                      ...homework,
                      needs_file_evidence: !homework.needs_file_evidence,
                    });
                  }}
                >
                  {homework.needs_file_evidence && <img src={check} alt="" />}
                </div>
              </div>

              {fileAws && (
                <div className={styles.containerFile}>
                  <div className={styles.firstColumnContainer}>
                    <img src={attach} alt="" />
                  </div>
                  <div className={styles.secondColumnContainer}>
                    <div className={styles.containerText}>
                      <div className={styles.titleFile}>{fileAws?.name}</div>
                      <div className={styles.extensionFile}>
                        {fileAws?.type}
                      </div>
                    </div>
                    <div className={styles.crossInstructions}>
                      {/* <img src={cross} alt="" /> */}
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.classroomListSelectButtonContainer}></div>
            </Panel>
            {selectedLesson?.lesson_type !== "redaction" && (
              <Panel
                forceRender={true}
                header={
                  <div
                    className={styles.panelTitle}
                    onClick={() =>
                      activeKey === 7 ? setActiveKey(0) : setActiveKey(7)
                    }
                  >
                    Intentos
                  </div>
                }
                key={7}
                showArrow={false}
                className={styles.customPanelStyle}
                extra={
                  activeKey !== 7 ? (
                    <button
                      onClick={() =>
                        activeKey === 7 ? setActiveKey(0) : setActiveKey(7)
                      }
                      className={styles.modifyTitle}
                    >
                      Elegir
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        activeKey === 7 ? setActiveKey(0) : setActiveKey(6)
                      }
                      className={styles.modifyTitleWhite}
                    >
                      Elegir
                    </button>
                  )
                }
              >
                <div className={styles.triesContainer}>
                  <div className={styles.titleHomeworkTries}>
                    Selecciona un número máximo de intentos. (Opcional)
                  </div>
                  <div>
                    <Select
                      className={styles.borderedSelect}
                      bordered={false}
                      defaultValue={
                        homework.tries ? [homework.tries?.toString()] : ["0"]
                      }
                      onChange={(values: string[]) => {
                        setHomework({ ...homework, tries: Number(values[0]) });
                      }}
                      options={[
                        { value: "0", label: "--" },
                        { value: "1", label: "1" },
                        { value: "2", label: "2" },
                        { value: "3", label: "3" },
                        { value: "4", label: "4" },
                        { value: "5", label: "5" },
                      ]}
                    />
                  </div>
                </div>
                <div className={styles.classroomListSelectButtonContainer}>
                  <div
                    className={styles.taskButton}
                    onClick={() => {
                      setActiveKey(0);
                    }}
                  >
                    Confirmar
                  </div>
                </div>
              </Panel>
            )}

            {selectedClassrooms?.length === 1 &&
              !(uploadHomework?.status === "finished") && (
                <Panel
                  header={
                    <div
                      className={styles.panelTitle}
                      onClick={() =>
                        activeKey === 8 ? setActiveKey(0) : setActiveKey(8)
                      }
                    >
                      Alumnos{" "}
                      <div className={styles.panelItemTitle}>
                        {selectedClassrooms &&
                          selectedClassrooms[0]?.group?.users?.length ===
                            idAlumns.length &&
                          "Todos los alumnos"}
                        {idAlumns.length === 0 && "Todos los alumnos"}
                      </div>
                    </div>
                  }
                  extra={
                    activeKey !== 8 ? (
                      <button
                        onClick={() =>
                          activeKey === 8 ? setActiveKey(0) : setActiveKey(8)
                        }
                        className={styles.modifyTitle}
                      >
                        Elegir
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          activeKey === 8 ? setActiveKey(0) : setActiveKey(8)
                        }
                        className={styles.modifyTitleWhite}
                      >
                        Elegir
                      </button>
                    )
                  }
                  key={8}
                  showArrow={false}
                  style={{
                    borderRadius: "10px",
                  }}
                  className={styles.customPanelStyle}
                >
                  <div className={isMobile ? styles.mobileContainer : ""}>
                    <div
                      className={styles.containerSearch}
                      style={{ height: isMobile ? "40px" : "" }}
                    >
                      <img src={searchIcon} alt="" />
                      <input
                        type="text"
                        placeholder="Buscar"
                        value={inputValue}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <Checkbox.Group
                    defaultValue={idAlumns}
                    value={idAlumns}
                    style={{ width: "98%" }}
                  >
                    <div className={styles.containerSelectCheck}>
                      <div onClick={handleCheckAll}> Seleccionar todo </div> /
                      <div onClick={handleCancelAll}>Deseleccionar todo</div>
                    </div>
                    <div className={styles.containerScroll}>
                      {selectedClassrooms &&
                        selectedClassrooms.length === 1 &&
                        selectedClassrooms[0].group?.users?.map((user) => {
                          //CONJUNTO DE ALUMNOS
                          return (
                            <Checkbox
                              value={user.id}
                              onChange={(e) => handleCheck(user.id, e)}
                              checked={idAlumns.includes(user.id)}
                              style={{ margin: "0px 0px 0px 5px" }}
                            >
                              {user.first_name} {user.last_name}
                            </Checkbox>
                          );
                        })}
                    </div>
                  </Checkbox.Group>
                  <div className={styles.classroomListSelectButtonContainer}>
                    <div
                      className={styles.taskButton}
                      onClick={() => {
                        setActiveKey(0);
                      }}
                    >
                      Confirmar
                    </div>
                  </div>
                </Panel>
              )}
          </Collapse>
        </div>
      )}
    </Drawer>
  );
};

export default AssingHomeworkDrawer;
