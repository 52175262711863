import { FC, useState, useEffect } from "react";
import { Filters } from "./NewFilters";
import { ActiveFilters } from "./ActiveFilters";
import styles from "./lessons.module.scss";
import settingsIcon from "../../assets/icons/settingsIcon.svg";
import leftarrow from "../../assets/icons/leftarrow.svg";
import { getLessonById_getLessonById } from "../../__generated__/gql-types/getLessonById";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import UseClassroomsByTeacher from "../../api/useGetClassroomsByTeacher";
import { Dosifications } from "./Dosification/Dosifications";
import { functionReturnIdSubject, functionReturnSubject } from "./Utils";
import useAuth from "../../context/useAuth";
import { ModalError } from "../Students/Missions/NewQuizz/ModalSave";
import UseGetCoursesBySubjectIdProgram2017 from "../../api/useGetCoursesBySubjectIdV2Program2017";
import { getCoursesBySubjectIdProgram2017_getCoursesBySubjectIdProgram2017 } from "../../__generated__/gql-types/getCoursesBySubjectIdProgram2017";
import { DrawerLessonNew } from "../Lessons/Drawer";

const Lessons: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { parametro1, parametro2, lessonIdParams } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const gradeParams = queryParams.get("grade");
  const pageParams = queryParams.get("page");
  const weekParams = queryParams.get("week_2017");
  const trimesterParams = queryParams.get("trimester");
  const courseParams = queryParams.get("course");
  // Obtener el valor de un parámetro de la consulta
  const getClassroomsByTeacher = UseClassroomsByTeacher();

  const getCoursesBySubjectIdV2 = UseGetCoursesBySubjectIdProgram2017();
  const [coursesState, setCoursesState] = useState<
    getCoursesBySubjectIdProgram2017_getCoursesBySubjectIdProgram2017[]
  >([]);

  const [visible, setVisible] = useState<boolean>(
    lessonIdParams ? true : false
  );

  const { userToken } = useAuth();
  // Query Lessons States
  const [grade, setGrade] = useState<number>(() => {
    const isStudent = userToken?.role === "student";
    const userGrade = userToken?.user_grade?.[0] ?? 6;
    const schoolName = userToken?.schools?.[0]?.name;
    const hasGradeParams = gradeParams !== undefined;

    const userGrades = userToken?.user_grade;
    let gradesSorted: number[] = [];
    if (userGrades && userGrades.length > 0) {
      userGrades.map((a) => gradesSorted.push(a));
    } else {
      gradesSorted.push(6);
    }
    gradesSorted.sort((a, b) => a - b);

    if (isStudent && userGrade) {
      return userGrade;
    } else if (!isStudent && userGrades) {
      return gradesSorted[0];
    } else if (schoolName === "Colegio Iluminación") {
      return 4;
    } else if (schoolName === "COLEGIO PROGRESO") {
      return 5;
    } else if (hasGradeParams) {
      return Number(gradeParams);
    } else {
      return 6;
    }
  });

  const [trimester, setTrimester] = useState<number>(
    trimesterParams ? Number(trimesterParams) : 1
  );
  const [block, setBlock] = useState<number>();
  const [weekList, setWeekList] = useState<string[]>();
  const [subjectId, setSubjectId] = useState<string>(
    functionReturnIdSubject(parametro2)
  );
  const [selectAcordion, setSelectAcordion] = useState<string>(
    courseParams ?? ""
  );
  const [sepBookPage, setSepBookPage] = useState<string>(pageParams ?? "");
  const [selectedWeek, setSelectedWeek] = useState<string>(weekParams ?? "");
  const [sepBookPagesList, setSepBookPagesList] = useState<string[]>();
  // Pagination
  const [lessonId, setLessonId] = useState<string>(lessonIdParams ?? "");

  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(
      location.state?.lesson ?? null
    );
  const [openFilter, setOpenFilter] = useState(
    weekParams || pageParams || Number(gradeParams) ? true : false
  );
  const [loadingDosification, setLoadingDosification] = useState(false);
  const [lessonViewSelected, setLessonViewSelected] = useState<string>(
    parametro1 ?? "dosification"
  );
  const [openModalError, setOpenModalError] = useState(false);
  const onCloseModalDelete = () => {
    setOpenModalError(false);
  };

  useEffect(() => {
    if (
      lessonViewSelected === "search" &&
      (weekParams || pageParams || Number(gradeParams))
    ) {
      setOpenFilter(true);
    }
  }, [weekParams, pageParams, gradeParams, lessonViewSelected]);
  // Individual Lesson Drawer Functions
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.delete("key");

      return params.toString();
    });
    setVisible(false);
    setLessonId("");
    setIndividualLesson(null);
  };

  // get Curses from dosification
  useEffect(() => {
    (async () => {
      setLoadingDosification(true);
      try {
        const response = await getCoursesBySubjectIdV2({
          getCoursesBySubjectIdInputProgram2017: {
            grade_id: grade,
            subject_ids: [Number(subjectId)],
            threemester_2017: trimester,
          },
        });
        if (response) {
          const coursesFilteredProgram2017 = response.filter(
            (course) => course.program_2017
          );
          const sortcoursesProgram2017 = coursesFilteredProgram2017.sort(
            (a, b) => {
              if (a.week_2017 && b.week_2017) return a.week_2017 - b.week_2017;
              return 0;
            }
          );
          setCoursesState(sortcoursesProgram2017);
        }

        setLoadingDosification(false);
      } catch (error) {
        setOpenModalError(true);

        console.log(error);
        setLoadingDosification(false);
      }
    })();
  }, [subjectId, grade, trimester, getCoursesBySubjectIdV2]);

  // changing params
  useEffect(() => {
    const nuevaUrl =
      lessonId !== ""
        ? `/2017/${lessonViewSelected}/${functionReturnSubject(
            subjectId
          )}/${lessonId}?grade=${grade}&page=${sepBookPage}&week=${selectedWeek}&trimester=${trimester}&course=${selectAcordion}`
        : `/2017/${lessonViewSelected}/${functionReturnSubject(
            subjectId
          )}?grade=${grade}&page=${sepBookPage}&week=${selectedWeek}&trimester=${trimester}&course=${selectAcordion}`;
    navigate(nuevaUrl);
  }, [
    navigate,
    subjectId,
    lessonViewSelected,
    lessonId,
    grade,
    sepBookPage,
    selectedWeek,
    trimester,
    selectAcordion,
  ]);

  return (
    <div className={styles.containerLesson}>
      <div className={styles.filtersAndTableContainer}>
        {openFilter && lessonViewSelected === "search" && (
          <div className={styles.filtersContainer}>
            <button
              name="filters"
              className={styles.itemsFilter}
              onClick={() => setOpenFilter(false)}
            >
              <span className={styles.itemFilter}>
                <img
                  src={settingsIcon}
                  alt=""
                  style={{ marginRight: "10px" }}
                />
                Filtros
              </span>
              <img src={leftarrow} alt="" />
            </button>
            <ActiveFilters
              sepBookPage={sepBookPage}
              sepBookPagesList={sepBookPagesList}
              setSepBookPage={setSepBookPage}
              setGrade={setGrade}
              grade={grade}
              setBlock={setBlock}
              block={block}
              weekList={weekList}
              setSelectedWeek={setSelectedWeek}
              selectedWeek={selectedWeek}
            />
          </div>
        )}

        {/* VISTA DE LECCIONES */}
        <div className={styles.tableContainer}>
          <Filters
            lessonViewSelected={lessonViewSelected}
            selectedItem={
              userToken?.schools[0].name === "Legionarios" ? "2" : subjectId
            }
            setSelectedItem={setSubjectId}
            setOpenFilter={setOpenFilter}
            openFilter={openFilter}
          />

          <Dosifications
            selectAcordion={selectAcordion}
            setSelectAcordion={setSelectAcordion}
            loadingDosification={loadingDosification}
            coursesState={coursesState}
            grade_id={grade === 0 ? 6 : grade}
            trimester={trimester}
            showDrawer={showDrawer}
            setTrimester={setTrimester}
            setLessonId={setLessonId}
            setIndividualLesson={setIndividualLesson}
            setGrade={setGrade}
            subjectId={subjectId}
          />
        </div>
      </div>

      <DrawerLessonNew
        onClose={onClose}
        visible={visible}
        lessonViewSelected={lessonViewSelected}
        back={"lessons"}
        individualLesson={individualLesson}
      />
      <ModalError
        onCloseModalDelete={onCloseModalDelete}
        open={openModalError}
      ></ModalError>
    </div>
  );
};

export default Lessons;
