import React, { FC } from "react";
import styles from "./titleDetailsHomework.module.scss";
import { getHomeworkById_getHomeworkById } from "../../../../__generated__/gql-types/getHomeworkById";
import { Skeleton } from "antd";
import closeIcon from "../../../../assets/icons/others/close-icon.svg";
import check from "../../../../assets/icons/checked_green.svg";
import calendar from "../../../../assets/icons/calendar-icon.svg";
import incourse from "../../../../assets/icons/inCourse-icon.svg";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";
import moment from "moment";
interface PropsTitle {
  task: getHomeworkById_getHomeworkById | null;
  onCloseTask: () => void;
  individualLesson: getLessonById_getLessonById | null;
}

const TitleDetailsHomework: FC<PropsTitle> = ({ task, onCloseTask }) => {
  const individualHomework: boolean = task?.individualHomework
    ? task.individualHomework
    : false;

  const students: number =
    individualHomework && task?.users?.length !== undefined
      ? task?.users?.length
      : task?.students_grades?.length !== undefined
      ? task.students_grades.length
      : 0;
  let approvedStudents: number = 0;
  let studentsParticipation: number = 0;

  const finalStudentsToRender = () => {
    let individualStudents = task?.users?.map((user) =>
      Number(user.id)
    ) as unknown as number[];
    let finalIndividualStudents: any[] = [];
    if (individualHomework) {
      task?.students_grades?.forEach((student) => {
        return individualStudents?.includes(
          student.student_id ? student.student_id : 0
        )
          ? finalIndividualStudents.push(student)
          : finalIndividualStudents.push({});
      });

      return finalIndividualStudents;
    }
    return task?.students_grades as unknown as any[];
  };

  //student participation & aproved percentage calc
  const studentsParticipationFinal = finalStudentsToRender();
  studentsParticipationFinal?.forEach((studentGrade) => {
    return studentGrade.high_score !== null && studentGrade.high_score >= 6
      ? (approvedStudents += 1) & (studentsParticipation += 1)
      : studentGrade.high_score !== null && studentGrade.delivered
      ? (studentsParticipation += 1)
      : studentGrade.delivered &&
        task?.lessons &&
        task?.lessons[0].lesson_type === "redaction"
      ? (studentsParticipation += 1)
      : undefined;
  });

  const percentageCalculator = (totalAmount: number, amount: number) => {
    return ((amount * 100) / totalAmount).toFixed();
  };
  const studentsParticipationPercentage = percentageCalculator(
    students,
    studentsParticipation
  );
  return task ? (
    <div className={styles.container}>
      <div className={styles.dataTask}>
        <div className={styles.containerContent}>
          <div className={styles.containerTitle}>
            <img
              src={closeIcon}
              alt="close icon"
              onClick={onCloseTask}
              className={styles.crossImageMobile}
            />
            <h1>
              {(task?.lessons && task?.lessons[0]?.title) ??
                "La lección no tiene título"}
            </h1>
            <>
              {task?.status === "finished" && (
                <div className={styles.statusContainer}>
                  <img src={check} alt="check" />
                  <h3>
                    Finalizada{" "}
                    <span>
                      {moment(task?.start_date, "DD-MM-YYYY HH:mm")
                        .format("DD-MM HH:mm")
                        .toString()}{" "}
                      -{" "}
                      {moment(task?.end_date, "DD-MM-YYYY HH:mm")
                        .format("DD-MM  HH:mm")
                        .toString()}
                    </span>
                  </h3>
                </div>
              )}

              {task?.status === "inCourse" && (
                <div className={styles.incourse}>
                  <img src={incourse} alt="incourse" />
                  <h3>
                    En curso{" "}
                    <span>
                      {moment(task?.start_date, "DD-MM-YYYY HH:mm")
                        .format("DD-MM HH:mm")
                        .toString()}{" "}
                      -{" "}
                      {moment(task?.end_date, "DD-MM-YYYY HH:mm")
                        .format("DD-MM HH:mm")
                        .toString()}
                    </span>
                  </h3>
                </div>
              )}

              {task?.status === "programed" && (
                <div className={styles.programmed}>
                  <img src={calendar} alt="calendar" />
                  <h3>
                    Programada
                    <span>
                      {moment(task?.start_date, "DD-MM-YYYY HH:mm")
                        .format("DD-MM HH:mm")
                        .toString()}
                      -
                      {moment(task?.end_date, "DD-MM-YYYY HH:mm")
                        .format("DD-MM HH:mm")
                        .toString()}
                    </span>
                  </h3>
                </div>
              )}
            </>
          </div>
          <div className={styles.quadsContainer}>
            <div className={styles.quad}>
              <h4>Aprobados (60%)</h4>
              <h2>
                {approvedStudents} /{" "}
                {individualHomework && task?.users?.length !== undefined
                  ? task?.users?.length
                  : task?.students_grades?.length}
              </h2>
            </div>
            <div className={styles.quad}>
              <h4>Participación</h4>
              <h2>{`${studentsParticipationPercentage}%`}</h2>
            </div>
          </div>
        </div>
        <img
          src={closeIcon}
          alt="close icon"
          onClick={onCloseTask}
          className={styles.crossImage}
        />
      </div>
    </div>
  ) : (
    <Skeleton active paragraph={{ rows: 1 }} style={{ margin: 3 }} />
  );
};

export default TitleDetailsHomework;
